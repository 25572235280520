<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>云店管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/YunDianManage">云店管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="javascript:void(0)" @click="handlePageGoodsManage"
              >商品管理</a
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="/#/SortManage">分类管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="/#/SortManage">编辑管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="/#/SortManage">添加分类</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <ul>
            <li
              @click="handeleEditSort(data.id)"
              v-for="data in SortList"
              :key="data.id"
            >
              <div class="content">
                <van-icon name="bars" size="1.3rem" />
                <span>{{ data.type_name }}</span>
              </div>
              <van-icon name="arrow" size="1rem" color="#999999" />
            </li>
          </ul>
          <a href="javascript:void(0)" class="AddSort" @click="handleAddSort()"
            >添加分类</a
          >
        </div>
        <div class="right" v-show="Oneshow">
          <div class="Editname">
            <p>分类ID</p>
            <input type="number" v-model="EditId" placeholder="请输入分类Id" />
          </div>

          <div class="Editname">
            <p>分类名称</p>
            <input
              type="text"
              v-model="EditName"
              placeholder="请输入分类名称"
            />
          </div>
          <div class="Editname">
            <p>排序</p>
            <input
              type="number"
              v-model="EditSortNum"
              placeholder="请输入排序序号"
            />
          </div>
          <div class="Editstatus">
            <p>分类状态</p>
            <van-switch v-model="Editchecked" size="24px" />
          </div>
          <div class="Editbtn">
            <a href="javascript:void(0)" @click="handleDelSort">删除分类</a>
            <a href="javascript:void(0)" @click="handleEditSave">提交保存</a>
          </div>
        </div>
        <!-- 添加分类 -->
        <div class="right" v-show="Twoshow">
          <div class="Addname">
            <p>分类名称</p>
            <input type="text" v-model="AddName" placeholder="请输入分类名称" />
          </div>
          <div class="Editname">
            <p>排序</p>
            <input
              type="number"
              v-model="AddSortNum"
              placeholder="请输入排序序号"
            />
          </div>
          <div class="Addstatus">
            <p>分类状态</p>
            <van-switch v-model="Addchecked" size="24px" />
          </div>
          <div class="Addbtn" @click="handleAddSave">
            <a href="javascript:void(0)">提交保存</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import {
  Popup,
  Dialog,
  Toast,
  Pagination,
  Empty,
  Icon,
  Circle,
  Switch,
} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Circle);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Popup);
Vue.use(Switch);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 编辑分类
      Oneshow: false,
      // 添加分类
      Twoshow: false,
      //   编辑分类名称
      EditName: "",
      //   编辑分类id
      EditId: "",
      // 编辑排序序号
      EditSortNum: "",
      //   编辑分类状态
      Editchecked: true,
      //   添加分类名称
      AddName: "",
      // 添加排序序号
      AddSortNum: "",
      //   添加分类状态
      Addchecked: true,
      // 分类列表
      SortList: "",
    };
  },
  mounted() {
    http
      .request({
        url: "/shop/yundianCategory",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.SortList = res.data.data;
        console.log(132, this.SortList);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   显示商品管理页面
    handleShowGoodsManage() {
      this.Twoshow = false;
      this.Oneshow = true;
    },
    // 跳转回商品管理页面
    handlePageGoodsManage() {
      this.$router.push({
        name: "YunDianManage",
        params: {
          Oneshow: true,
        },
      });
    },
    // 编辑分类
    handeleEditSort(id) {
      this.Twoshow = false;
      this.Oneshow = true;
      http
        .request({
          url: `/shop/yundianCategoryEdit?id=${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(123, res);
          this.EditName = res.data.data.type_name;
          this.EditSortNum = res.data.data.sort;
          this.EditId = res.data.data.id;
          if (res.data.data.open == 0) {
            this.Editchecked = true;
          } else {
            this.Editchecked = false;
          }
        });
    },
    // 添加分类
    handleAddSort() {
      this.Oneshow = false;
      this.Twoshow = true;
    },
    // 添加分类提交
    handleAddSave() {
      var checked = 0;
      if (this.Addchecked == true) {
        checked = 0;
      } else {
        checked = 1;
      }
      http
        .request({
          url: "/shop/yundianCategoryAdd",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            type_name: this.AddName,
            sort: this.AddSortNum,
            open: checked,
          },
        })
        .then((res) => {
          Toast(res.data.msg);
          this.Twoshow = false;
          // console.log(132, this.SortList);
          http
            .request({
              url: "/shop/yundianCategory",
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.SortList = res.data.data;
              console.log(132, this.SortList);
            });
        });
    },
    // 编辑分类提交
    handleEditSave() {
      var checked = 0;
      if (this.Editchecked == true) {
        checked = 0;
      } else {
        checked = 1;
      }
      http
        .request({
          url: "/shop/yundianCategoryEdit",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id: this.EditId,
            type_name: this.EditName,
            sort: this.EditSortNum,
            open: checked,
          },
        })
        .then((res) => {
          this.Oneshow = false;
          Toast(res.data.msg);
          http
            .request({
              url: "/shop/yundianCategory",
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.SortList = res.data.data;
              console.log(132, this.SortList);
            });
        });
    },
    // 删除分类
    handleDelSort() {
      var id = this.EditId;
      Dialog.confirm({
        title: "您确定要删除此分类吗?",
      })
        .then(() => {
          // on confirm
          http
            .request({
              url: `/shop/yundianCategoryDel?id=${id}`,
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.Oneshow = false;
              Toast(res.data.msg);
              http
                .request({
                  url: "/shop/yundianCategory",
                  method: "get",
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((res) => {
                  this.SortList = res.data.data;
                  console.log(132, this.SortList);
                });
            });
        })
        .catch(() => {
          console.log("取消删除分类");
        });
    },
  },
  computed: {
    Onetext() {
      return this.OnecurrentRate.toFixed(0) + "%";
    },
    Twotext() {
      return this.TwocurrentRate.toFixed(0) + "%";
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
// 左右内容
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    background: #f4f4f4;
    position: relative;
    // 左边
    ul {
      width: 100%;
      height: 46.5rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      background: #fff;
      padding-top: 0.77rem;
      margin-bottom: 3rem;
      overflow: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */

      li {
        width: 92%;
        margin: 0 auto;
        height: 3.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #f7f7f7;
        flex-shrink: 0;
        .content {
          display: flex;
          align-items: center;
          span {
            margin-left: 0.47rem;
            font-size: 0.95rem;
          }
        }
      }
      li:last-child {
        border: none;
      }
    }
    ul::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .AddSort {
      width: 100%;
      height: 2.8rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fc4039;
      font-size: 0.95rem;
      letter-spacing: 0.1rem;
      color: #fff;
      font-weight: bold;
      position: absolute;
      bottom: 0;
    }
  }
}

// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #f4f4f4;
  .Editname {
    width: 100%;
    height: 2.8rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    margin-bottom: 1rem;
    p {
      margin-left: 0.77rem;
      font-size: 0.95rem;
    }
    input {
      width: 50%;
      height: 1.8rem;
      border: none;
      text-align: right;
      font-size: 0.95rem;
      margin-right: 1rem;
    }
  }
  .Editstatus {
    width: 100%;
    height: 2.8rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    margin-top: 1rem;
    p {
      margin-left: 0.77rem;
      font-size: 0.95rem;
    }
    .van-switch {
      margin-right: 1rem;
    }
  }
  .Editbtn {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3.6rem;
    a {
      width: 8.8rem;
      height: 2.2rem;
      background: #fa3433;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 0.95rem;
      border-radius: 0.36rem;
    }
    a:nth-child(2) {
      width: 8.8rem;
      height: 2.2rem;
      background: #18be6a;
    }
  }

  .Addname {
    width: 100%;
    height: 2.8rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    margin-bottom: 1rem;
    p {
      margin-left: 0.77rem;
      font-size: 0.95rem;
    }
    input {
      width: 50%;
      height: 1.8rem;
      border: none;
      text-align: right;
      font-size: 0.95rem;
      margin-right: 1rem;
    }
  }
  .Addstatus {
    width: 100%;
    height: 2.8rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    margin-top: 1rem;
    p {
      margin-left: 0.77rem;
      font-size: 0.95rem;
    }
    .van-switch {
      margin-right: 1rem;
    }
  }
  .Addbtn {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3.6rem;
    a {
      width: 14.7rem;
      height: 2.4rem;
      background: #fa3433;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 0.95rem;
      border-radius: 0.36rem;
    }
  }
}
</style>