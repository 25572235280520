<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>云店管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/YunDianManage">云店管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="javascript:void(0)" @click="handlePageGoodsManage"
              >商品管理</a
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="/#/EditGoods">编辑商品</a></el-breadcrumb-item
          >
          <!-- <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="/SortManage">编辑管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="/SortManage">添加分类</a></el-breadcrumb-item
          > -->
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="GoodsPic">
            <p>商品图片</p>
            <div class="pic">
              <!-- <van-uploader
                :after-read="afterRead"
                multiple
                v-model="fileList"
                :preview-full-image="false"
                :max-count="6"
                @delete="afterDel"
              /> -->
              <div class="piclist">
                <img
                  :src="data"
                  alt=""
                  v-for="(data, index) in imagelist"
                  :key="data"
                  @click="handleClickPic(data, index)"
                />

                <a href="javascript:void(0)" class="imageFileInput" v-show="imagelist.length<6">
                  <input
                    class="fileInput"
                    type="file"
                    multiple="multiple"
                    @change="handleChooseImg"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="GoodsInfo">
            <div class="content">
              <p>商品名称</p>
              <input type="text" placeholder="请输入商品名称" v-model="title" />
            </div>
            <div class="content">
              <p>商品原价</p>
              <input type="text" placeholder="请输入商品原价" v-model="price" />
            </div>
            <div class="content">
              <p>vip原价</p>
              <input
                type="text"
                placeholder="请输入商品vip价格"
                v-model="vipprice"
              />
            </div>
            <div class="content">
              <p>商品单位</p>
              <input
                type="text"
                placeholder="请输入商品单位,比如:斤,千克,只"
                v-model="company"
              />
            </div>
            <div class="content">
              <p>商品保质期</p>
              <input
                type="text"
                placeholder="请输入商品保质期,比如:10天,1月,半年"
                v-model="qa"
              />
            </div>
            <!-- <div class="content">
              <p>商品库存</p>
              <input type="text" placeholder="不填为不限量" />
            </div> -->
            <div class="content">
              <p>商品分类</p>
              <input
                type="text"
                @click="show = true"
                placeholder="请选择分类"
                v-model="sortName"
                style="color: #757575"
              />
            </div>
          </div>
          <div class="status">
            <div class="content">
              <p>商品状态</p>
              <van-switch v-model="checked" size="24px" />
            </div>
            <!-- <div class="content">
                  <p>推荐商品</p>
                  <van-switch v-model="checked" size="24px" />
              </div> -->
          </div>
          <div class="btn">
             <a href="javascript:void(0)" class="DelBtn" @click="handleDelGoods">
            删除商品
          </a>
            <a href="javascript:void(0)" class="SaveBtn" @click="handleSaveBtn">
            提交保存
          </a>
          </div>
          
          <van-popup
            v-model="show"
            position="bottom"
            :style="{ height: '30%' }"
          >
            <van-picker
              title="商品分类"
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="onCancel"
            />
          </van-popup>
        </div>
        <div class="right" v-show="Oneshow">77</div>
        <!-- 添加分类 -->
        <div class="right" v-show="Twoshow">88</div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import {
  Popup,
  Dialog,
  Toast,
  Pagination,
  Empty,
  Icon,
  Circle,
  Switch,
  Uploader,
  Picker,
} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Circle);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Uploader);
Vue.use(Picker);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 编辑分类
      Oneshow: false,
      // 添加分类
      Twoshow: false,
      //  商品图片
      fileList: [],
      //   商品分类下拉框显示
      show: false,
      //   商品分类
      columns: [],
      // 产品分类
      sortList: "",
      // 产品id
      type_id: "",
      // 产品名称
      title: "",
      // 产品原价
      price: "",
      // 产品vip价格
      vipprice: "",
      //   商品状态
      checked: true,
      // 上传网络图片列表
      imagelist: [],
      // 产品单位
      company: "",
      // 产品保质期
      qa: "",
      // 产品最终状态
      open: 0,
      // 产品选择分类显示文字
      sortName: "请选择分类",
      // 产品id
      id: "",
      imgUrl: "",
    };
  },
  mounted() {
    console.log("id", this.$route.query.id);
    this.id = this.$route.query.id;
    http
      .request({
        url: `/shop/yundianGoodsEdit?id=${this.id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.sortList = res.data.data.category;
        this.sortList.forEach((item) => {
          console.log(item.type_name);
          this.columns.push(item.type_name);
        });
        console.log(132, this.sortList);
        console.log("产品修改查询", res.data.data.info);
        this.title = res.data.data.info.title;
        this.price = res.data.data.info.price;
        this.vipprice = res.data.data.info.vip_price;
        this.company = res.data.data.info.company;
        this.qa = res.data.data.info.qa;
        if (res.data.data.info.open == 1) {
          this.checked = true;
        } else {
          this.checked = false;
        }
        // 77
        this.type_id = res.data.data.info.type_id
        // this.sortName = this.sortList[index].type_name
        console.log("分类id",this.type_id)

        //  var result = this.sortList.find(item=>item.type_id == this.type_id)
        var obj = {}
        this.sortList.forEach((item)=>{

          if(item.id == this.type_id){
            obj = item
          }
        })
        console.log(77,obj)
        this.sortName = obj.type_name

        // 商品图片
         this.imagelist = res.data.data.info.image
        //  this.fileList = res.data.data.info.image
          console.log(999,res.data.data.info.image)
      });
     
    // 77
    // http
    //   .request({
    //     url: "/shop/yundianGoodsAdd",
    //     method: "get",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((res) => {
    //     this.sortList = res.data.data;
    //     this.sortList.forEach((item) => {
    //       console.log(item.type_name);
    //       this.columns.push(item.type_name);
    //     });
    //     console.log(132, this.sortList);
    //   });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
     handleChooseImg(e) {
      var that = this;
      var img = e.target.files[0];
      console.log(img);
      var reader = new FileReader();
      reader.readAsDataURL(img);
      // console.log(reader)

      reader.onload = function (e) {
        console.log("base64", e);
        var url = e.target.result;

        http
          .request({
            url: "/shop/yundianUploadImg",
            method: "post",
            headers: {
              // "Content-Type": "application/json",
              "Content-Type": " multipart/form-data",
            },
            data: {
              img: url,
            },
          })
          .then((res) => {
            console.log("上传图片", res.data.data.image_path);
            // console.log(987, this.fileList);
            Toast(res.data.msg);
            that.imagelist.push(res.data.data.image_path);
            console.log("图片列表", that.imagelist);
          });
      };
    },
     // 删除图片
    handleClickPic(data, index) {
      console.log(data, index);
      Dialog.confirm({
        title: "确定要删除此图片吗",
      })
        .then(() => {
          // on confirm
          this.imagelist.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    //   显示商品管理页面
    handleShowGoodsManage() {
      this.Twoshow = false;
      this.Oneshow = true;
    },
    // 跳转回商品管理页面
    handlePageGoodsManage() {
      this.$router.push({
        name: "YunDianManage",
        params: {
          Oneshow: true,
        },
      });
    },
    // 添加图片
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log("添加", file.content, this.fileList);
      http
        .request({
          url: "/shop/yundianUploadImg",
          method: "post",
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": " multipart/form-data",
          },
          data: {
            img: file.content,
          },
        })
        .then((res) => {
          console.log("上传图片", res);
          console.log(987, this.fileList);
          Toast(res.data.msg);
          this.imagelist.push(res.data.data.image_path);
          console.log("图片列表", this.imagelist);
        });
    },
    // 删除图片
    afterDel(file) {
      console.log("删除", file.content, this.fileList);
    },
    // 选择商品分类确定
    onConfirm(value, index) {
      this.show = false;
      this.type_id = this.sortList[index].id;
      // Toast(`当前值：${value}, 当前商品id：${this.type_id}`);
      this.sortName = this.sortList[index].type_name;
    },
    onCancel() {
      Toast("取消");
      this.show = false;
    },
    // 提交保存
    handleSaveBtn() {
      console.log(this.type_id);
      if (
        this.imagelist == [] ||
        this.imagelist == "" ||
        this.imagelist == "undefined" ||
        this.imagelist == null
      ) {
        Toast("请选择商品图片");
        return;
      } else if (this.title == "") {
        Toast("请输入产品名称");
        return;
      } else if (this.price == "") {
        Toast("请输入产品原价");
        return;
      } else if (this.vipprice == "") {
        Toast("请输入产品vip原价");
        return;
      } else if (this.company == "") {
        Toast("请输入产品单位");
        return;
      } else if (this.qa == "") {
        Toast("请输入产品保质期");
        return;
      } else if (this.type_id == "") {
        Toast("请选择产品分类");
        return;
      }

      if (this.checked == true) {
        this.open = 1;
      } else {
        this.open = 0;
      }
      http
        .request({
          url: "/shop/yundianGoodsEdit",
          method: "post",
          headers: {
            // "Content-Type": "application/json",
            // "Content-Type": " multipart/form-data",
          },
          data: {
            type_id: this.type_id,
            title: this.title,
            price: this.price,
            vip_price: this.vipprice,
            open: this.open,
            image: this.imagelist,
            company: this.company,
            qa: this.qa,
            id:this.id
          },
        })
        .then((res) => {
          console.log("点击提交保存", res);
          if (res.data.code == 1) {
            this.$router.push({
              name: "YunDianManage",
              params: {
                Oneshow: true,
              },
            });
            Toast(res.data.msg);
          } else {
            Toast(res.data.msg);
          }
        });
    },
    // 删除商品
    handleDelGoods(){
      var id = this.id
       Dialog.confirm({
        title: "确定要删除此商品吗",
      })
        .then(() => {
          http
        .request({
          url: `/shop/yundianGoodsDel?id=${id}`,
          method: "get",
          headers: {
            // "Content-Type": "application/json",
            // "Content-Type": " multipart/form-data",
          }
        })
        .then((res) => {
          console.log("删除此产品", res);
          if (res.data.code == 1) {
            this.$router.push({
              name: "YunDianManage",
              params: {
                Oneshow: true,
              },
            });
            Toast(res.data.msg);
          } else {
            Toast(res.data.msg);
          }
        });
        })
        .catch(() => {
          // on cancel
        });
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
// 左右内容
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    background: #f4f4f4;
    position: relative;
    // 左边
    .GoodsPic {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      p {
        width: 96%;
        margin: 0 auto;
        height: 2.8rem;
        border-bottom: 1px solid #f7f7f7;
        display: flex;
        align-items: center;
        font-size: 0.95rem;
      }
      .pic {
        width: 96%;
        margin: 0 auto;
        margin-top: 0.5rem;
      }
    }
    .GoodsInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      margin-top: 1rem;
      .content {
        width: 96%;
        height: 2.8rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f7f7f7;
        p {
          font-size: 0.95rem;
        }
        input {
          height: 1.8rem;
          width: 50%;
          border: none;
          text-align: right;
          font-size: 0.95rem;
        }
        span {
          font-size: 0.95rem;
        }
      }
    }
    .status {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      margin-top: 1rem;
      .content {
        width: 96%;
        height: 2.8rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f7f7f7;
        p {
          font-size: 0.95rem;
        }
      }
    }
    .btn {
      width:100%;
      height: 3.8rem;
      // background: #f93e3d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.95rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      .DelBtn{
      width: 10rem;
        height: 2.4rem;
        background: #ff9800;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.95rem;
      margin-left: 8rem;
      border-radius: .5rem;
      }
        .SaveBtn{
        width: 10rem;
        height: 2.4rem;
        background: #f93634;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.95rem;
      margin-right: 8rem;
      border-radius: .5rem;

      }
    }
  }

  // 右边
  .VipcardSettingMain .right {
    width: 40%;
    height: 50rem;
    background: #f4f4f4;
  }
}

.VipcardSettingMain .left .pic {
  // background: pink;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  .piclist {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    img {
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}

/*定义图像以及大小*/
.imageFileInput {
  width: 5rem;
  height: 5rem;
  // position: absolute;
  background-image: url(../../../public/images/index/img64.png); /*这里可以换成图片路径（background-image：../img/....）注意图片路径*/
}

/*定义上传*/
.fileInput {
  width: 5rem;
  height: 100%;
  // position: absolute;
  // right: 0;
  // top: 0;
  opacity: 0;
}
</style>