import { render, staticRenderFns } from "./VipcardSetting.vue?vue&type=template&id=3d5f2c28&scoped=true&"
import script from "./VipcardSetting.vue?vue&type=script&lang=js&"
export * from "./VipcardSetting.vue?vue&type=script&lang=js&"
import style0 from "./VipcardSetting.vue?vue&type=style&index=0&id=3d5f2c28&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d5f2c28",
  null
  
)

export default component.exports