<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>会员卡设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/VipcardSetting">会员卡设置</a></el-breadcrumb-item
          >
          <!-- <el-breadcrumb-item v-show="Oneshow == true"><a href="javascript:void(0)">会员卡充值设置</a></el-breadcrumb-item> -->
          <el-breadcrumb-item
            ><a href="javascript:void(0)">套餐卡设置</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <!-- 套餐卡设置 -->
        <div class="right">
          <div class="RechargeableCardSetting_main">
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">套餐卡1</div>
                  <div class="Money">¥1888.00</div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="Youshow = true">套餐</div>
                <div>删除</div>
              </div>
            </div>
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">套餐卡2</div>
                  <div class="Money">¥110.00</div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="Youshow = true">套餐</div>
                <div>删除</div>
              </div>
            </div>
          </div>
          <div class="AddRechargeableBtn">
            <div class="icon">
              <img src="../../../public/images/index/img27.png" alt="" />
            </div>
            <span @click="TaoCanEditMaskshow = true">添加套餐卡</span>
          </div>
        </div>
        <van-popup v-model="TaoCanEditMaskshow" round>
          <div class="mask" style="height: 20rem">
            <div class="top">
              <span>添加套餐卡</span>
              <i
                class="iconfont icon-guanbi"
                @click="TaoCanEditMaskshow = false"
              ></i>
            </div>
            <div class="content">
              <p>
                <span>套餐名称</span>
                <input type="text" placeholder="请输入套餐卡名称" />
              </p>
            </div>
            <div class="content">
              <p>
                <span>套餐价格</span>
                <input type="text" placeholder="请输入金额" />
              </p>
            </div>
            <div class="content">
              <p>
                <span>有效期(天)</span>
                <input type="number" placeholder="请输入有效期天数" />
              </p>
            </div>
            <a href="javascript:void(0)" @click="TaoCanEditMaskshow = false"
              >确定</a
            >
          </div>
        </van-popup>
        <!-- 套餐产品列表 -->
        <div class="you" style="position: relative"  v-show="Youshow">
          <div class="RechargeableCardSetting_main">
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">洗车</div>
                  <div class="Money">¥88.00 <span style="margin-left:1.2rem">7次</span></div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="PackageCardMaskshow = true">编辑</div>
                <div>删除</div>
              </div>
            </div>
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">汽车保养</div>
                  <div class="Money">¥366.00 <span style="margin-left:1.2rem">1次</span></div>
                </div>
              </div>
              <div class="btnRight">
                <div>编辑</div>
                <div>删除</div>
              </div>
            </div>
          </div>
          <div
            class="AddRechargeableBtn"
            style="position: absolute; left: 0; bottom: 0; z-index: 999"
             @click="PackageCardAddMaskshow = true" 
          >
            <div class="icon">
              <img src="../../../public/images/index/img27.png" alt="" />
            </div>
            <span>添加产品</span>
          </div>
        </div>
           <van-popup v-model="PackageCardMaskshow" round>
          <div class="mask">
            <div class="top">
              <span>修改套餐项目</span>
              <i class="iconfont icon-guanbi" @click="PackageCardMaskshow = false"></i>
            </div>
            <div class="content">
              <p>
                <span>项目产品</span>
                <input type="text" />
              </p>
            </div>
            <div class="content">
              <p>
                <span>次数</span>
                <input type="text"/>
              </p>
            </div>
            <a href="javascript:void(0)" @click="PackageCardMaskshow = false">立即修改</a>
          </div>
        </van-popup>
               <van-popup v-model="PackageCardAddMaskshow" round>
          <div class="mask" style="height:20rem">
            <div class="top">
              <span>添加套餐产品</span>
              <i class="iconfont icon-guanbi" @click="PackageCardAddMaskshow = false"></i>
            </div>
            <div class="content">
              <p>
                <span>项目分类</span>
                <input type="text" placeholder="请选择分类"/>
              </p>
            </div>
            <div class="content">
              <p>
                <span>项目产品</span>
                <input type="text" placeholder="请选择项目产品"/>
              </p>
            </div>
             <div class="content">
              <p>
                <span>次数</span>
                <input type="text" placeholder="请输入次数"/>
              </p>
            </div>
            <a href="javascript:void(0)" @click="PackageCardAddMaskshow = false">立即添加</a>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup } from "vant";

Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>会员卡设置=>会员卡充值设置
      Youshow: false,
      //遮罩层(套餐卡编辑 编辑)
     PackageCardMaskshow: false,
      //遮罩层(套餐卡设置 添加产品)
      PackageCardAddMaskshow: false,
      // 首页=>会员卡设置=>套餐卡设置
      Twoshow: false,
      //遮罩层(套餐卡设置 编辑)
      TaoCanEditMaskshow: false,
    };
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    .main {
      display: flex;
      flex-direction: column;
      .content {
        width: 86%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftContent {
          display: flex;
          align-items: center;
          .img {
            width: 2.5625rem;
            height: 1.8125rem;
            img {
              width: 100%;
            }
          }
          span {
            font-size: 0.875rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}
// 左边
.VipCardSetting_content {
  width: 100%;
  /* margin-top: 0.77rem; */
  background: #fff;
}
.VipCardSetting_content ul {
  width: 80%;
  margin: 0 auto;
}

.VipCardSetting_content ul li {
  height: 3.625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  border-bottom: 1px solid #f3f3f3;
}
.VipCardSetting_content ul li .code_left {
  width: 45%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* background:pink; */
}
.VipCardSetting_content ul li .code_left .code_img {
  /* width: 1.4rem; */
  /* margin-right: 0.77rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: skyblue; */
}
.VipCardSetting_content ul li .code_left .code_img img {
  width: 100%;
}

.VipCardSetting_content ul li .code_left p {
  margin-left: 1rem;
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}
.RechargeableCardSetting_main {
  height: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 5rem;
}
.RechargeableCardSetting_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
}
.RechargeableCardSetting_content .Left {
  width: 62%;
  /* background: skyblue; */
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .Left .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.55rem;
  display: inline-block;
  flex-shrink: 0;
}
.RechargeableCardSetting_content .Left .icon img {
  width: 100%;
}
.RechargeableCardSetting_content .Left .info {
  flex: 1;
  /* background: orchid; */
}
.RechargeableCardSetting_content .Left .info .Notice {
  width: 10rem;
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.95rem;
}
.RechargeableCardSetting_content .Left .info .Money {
  width: 10rem;
  margin-top: 0.3rem;
  font-size: 0.75rem;
  color: #ff878b;
  /* margin-top: 0.18rem; */
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RechargeableCardSetting_content .btnRight {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .btnRight div {
  width: 4rem;
  height: 1.65rem;
  border: 1px solid #d8d8dc;
  font-size: 0.85rem;
  margin-left: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.AddRechargeableBtn {
  width: 100%;
  height: 4.5rem;
  border-top: 0.1875rem solid #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  margin-top: 4rem;
}
.AddRechargeableBtn .icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.55rem;
}
.AddRechargeableBtn img {
  width: 100%;
}
.mask {
  width: 19.125rem;
  height: 15.75rem;
  background: #f2f2f2;
  border-radius: 1.25rem;
  .top {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    background: #fff;
    letter-spacing: 0.03rem;
    margin-bottom: 0.7rem;
    i {
      position: absolute;
      right: 1rem;
      font-size: 1.3rem;
      color: #999999;
    }
  }
  .content {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f7f7f7;
    p {
      width: 88%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 0.9rem;
        font-weight: bold;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        flex: 1;
        margin-left: 1rem;
        height: 2.5rem;
        border: none;
        font-size: 0.9rem;
        text-align: left;
      }
    }
  }
  a {
    width: 80%;
    margin: 0 auto;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fa4347;
    font-size: 0.95rem;
    color: #fff;
    border-radius: 1.25rem;
    margin-top: 2.2rem;
    letter-spacing: 0.2rem;
  }
}
.you {
  width: 40%;
  height: 50rem;
  background: #f9f8f8;
  margin-right: 5rem;
}
</style>