<template>
  <div class="Tabbar">
    <div class="TabbarLogo">
      <img src="../../public/images/index/Logo.png" alt="Logo" />
    </div>
    <ul>
      <li :class="type == 1 ? 'TabbarActive' : ''" v-on:click="$emit('handlePageIndex')">
        <div class="icon">
          <img src="../../public/images/index/tabbar1.png" alt="首页" />
        </div>
        <span>首页</span>
      </li>
      <li :class="type == 2 ? 'TabbarActive' : ''">
        <div class="icon">
          <img src="../../public/images/index/tabbar2.png" alt="会员开卡" />
        </div>
        <span>会员开卡</span>
      </li>
      <li :class="type == 3 ? 'TabbarActive' : ''">
        <div class="icon">
          <img src="../../public/images/index/tabbar3.png" alt="预约管理" />
        </div>
        <span>预约管理</span>
      </li>
      <li :class="type == 4 ? 'TabbarActive' : ''">
        <div class="icon">
          <img src="../../public/images/index/tabbar4.png" alt="在线查询" />
        </div>
        <span>在线查询</span>
      </li>
    </ul>
    <div class="TabbarLogOut" @click="handleLoginOut">
      <div class="icon">
        <img src="../../public/images/index/tabbar5.png" alt="退出登录" />
      </div>
      <span>退出登录</span>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";

// Dialog({ message: "提示" });
export default {
  props: ["type"],
  data() {
    return {
      // type: 1
    };
  },
  mounted() {
    console.log(this.type);
  },
  methods: {
    handleLoginOut() {
      Dialog.confirm({
        title: "退出登录",
        message: "您确定要退出登录吗?",
      })
        .then(() => {
          localStorage.removeItem("Authorization");
          this.$router.push("/login");
        })
        .catch(() => {
          console.log("取消退出登录");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.Tabbar {
  width: 9%;
  height: 100%;
  background: #121723;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999999;
  .TabbarLogo {
    width: 5.5rem;
    height: 1.625rem;
    margin: 0 auto;
    margin-top: 3.125rem;
    img {
      width: 100%;
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    li {
      width: 100%;
      height: 3rem;
      display: flex;
      align-items: center;
      color: #fff;
      margin-bottom: 1.25rem;
      .icon :nth-child(1) {
        width: 0.875rem;
        height: 0.75rem;
        margin-left: 2.5rem;
        img {
          width: 100%;
        }
      }
      .icon :nth-child(2) {
        width: 0.875rem;
        height: 0.75rem;
        margin-left: 2.2rem;
        img {
          width: 100%;
        }
      }
      .icon :nth-child(3) {
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 2.2rem;
        img {
          width: 100%;
        }
      }
      .icon :nth-child(4) {
        width: 1rem;
        height: 0.875rem;
        margin-left: 2.2rem;
        img {
          width: 100%;
        }
      }
      span {
        font-size: 0.875rem;
        margin-left: 0.75rem;
      }
    }
  }
  .TabbarActive {
    width: 100%;
    background: linear-gradient(17deg, #fc4a30 0%, #fa2052 100%);
    opacity: 0.77;
    border-left: 0.4rem solid #fc0c35;
    box-sizing: border-box;
    .icon {
      margin-left: -0.4rem;
    }
  }
  .TabbarLogOut {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 1.25rem;
    position: absolute;
    bottom: 5%;
    .icon {
      width: 1rem;
      height: 0.875rem;
      margin-left: 2.2rem;
      img {
        width: 100%;
      }
    }
    span {
      font-size: 0.875rem;
      margin-left: 0.75rem;
      margin-top: 0.25rem;
    }
  }
}
</style>