<template>
  <div class="VipManageAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipManage">
      <UserTitle>
        <template v-slot:title>会员管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="javascript:void(0)">会员管理</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipManageMain">
        <div class="VipNumber">
          <div class="content">
            <p>{{ HeadList.user_today_total }}</p>
            <span>新增会员</span>
          </div>
          <div class="content">
            <p>{{ HeadList.user_month_total }}</p>
            <span>本月新增会员</span>
          </div>
          <div class="content">
            <p>{{ HeadList.user_total }}</p>
            <span>会员总数</span>
          </div>
        </div>
        <div class="tabRecord">
          <a
            href="javascript:void(0)"
            :class="current == index ? 'active' : ''"
            v-for="(data, index) in navList"
            :key="data"
            @click="handleChangeNav(index)"
            ><span
              ><b>{{ data }}</b></span
            ></a
          >
          <!-- <a href="javascript:void(0)"
            ><span><b>累计充值</b></span></a
          >
          <a href="javascript:void(0)"
            ><span><b>累计消费</b></span></a
          >
          <a href="javascript:void(0)"
            ><span><b>累计红包</b></span></a
          >
          <a href="javascript:void(0)"
            ><span><b>充值会员</b></span></a
          >
          <a href="javascript:void(0)"
            ><span><b>进店次数</b></span></a
          > -->
        </div>
        <div class="tabMain">
          <div class="tabContent">
            <div class="nav">
              <span style="width: 22%">会员名称</span>
              <span style="width: 11.3%">消费金额</span>
              <span style="width: 20%">会员卡金额</span>
              <span style="width: 22%">注册时间</span>
              <span style="width: 11.3%">到店次数</span>
              <span style="flex: 1">操作</span>
            </div>
            <div class="content"  v-for="data in datalist" :key="data.id">
              <div class="name">
                <div class="tx">
                  <img :src="data.avatar" alt="头像" />
                </div>
                <span>{{data.username}}</span>
              </div>
              <div class="money">¥<span>{{data.price}}</span></div>
              <div class="type">¥<span>{{data.bonus_money}}</span></div>
              <div class="time">{{data.reg_time}}</div>
              <div class="number"><span>累积到店{{data.shop_num}}次</span></div>

              <div class="btn" @click="handlePAgeDetail(data.id)">
                <a href="javascript:void(0)">查看详情</a>
              </div>
            </div>
            <!-- <div class="content">
              <div class="name">
                <div class="tx">
                  <img src="../../../public/images/index/img1.png" alt="头像" />
                </div>
                <span>测试账号测试账号测试账号</span>
              </div>
              <div class="money">¥<span>1.00</span></div>
              <div class="type">¥<span>1.00</span></div>
              <div class="time">08-31 15:10</div>
              <div class="number"><span>累积到店1次</span></div>

              <div class="btn">
                <a href="/VipManage/VipDetail">查看详情</a>
              </div>
            </div>
            <div class="content">
              <div class="name">
                <div class="tx">
                  <img src="../../../public/images/index/img1.png" alt="头像" />
                </div>
                <span>测试账号测试账号测试账号</span>
              </div>
              <div class="money">¥<span>1.00</span></div>
              <div class="type">¥<span>1.00</span></div>
              <div class="time">08-31 15:10</div>
              <div class="number"><span>累积到店1次</span></div>

              <div class="btn">
                <a href="/VipManage/VipDetail">查看详情</a>
              </div>
            </div> -->
            <div class="pagenation">
              <van-pagination v-model="page" mode="simple" :total-items="total" @change ="handleChangePage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/util/http";
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Pagination } from "vant";

Vue.use(Pagination);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      HeadList: "",
      navList: [
        "默认",
        "累计充值",
        "累计消费",
        "累计红包",
        "充值会员",
        "进店次数",
      ],
      current: 0,
      datalist: [],
      page: 1,
      totalPage: "",
      per_page:"",
      total:0
    };
  },
  mounted() {
    http
      .request({
        url: "shop/indexUserHead",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.HeadList = res.data.data;
        console.log(1, this.HeadList);
      });
      this.handleChangeNav(this.current)
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    handleChangeNav(index) {
      this.current = index;
      this.page = 1
      // 77
      let { page, datalist, current } = this;
      console.log(22222, current);
      http
        .request({
          url: "shop/indexUserList",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            page: page,
            type: current,
          },
        })
        .then((res) => {
          // if (page > 1 && datalist.length > 0) {
            // datalist = datalist.concat(res.data.data.data);
          // } else {
            datalist = [];
            datalist = res.data.data.data;
          // }
          this.datalist = datalist;
          this.totalPage = res.data.data.last_page;
          this.per_page = res.data.data.per_page
          this.total = res.data.data.total
          console.log(778, this.datalist);
        });
    },
    handleChangePage(){
        console.log(this.page)
      let { page, datalist, current } = this;

              http
        .request({
          url: "shop/indexUserList",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            page: page,
            type: current,
          },
        })
        .then((res) => {
          // if (page > 1 && datalist.length > 0) {
            // datalist = datalist.concat(res.data.data.data);
          // } else {
            datalist = [];
            datalist = res.data.data.data;
          // }
          this.datalist = datalist;
          this.totalPage = res.data.data.last_page;
          this.per_page = res.data.data.per_page
          console.log(778, this.datalist);
        });
    },
    // 跳转会员详情
    handlePAgeDetail(id){
        // this.$router.push({
        //     name:'VipDetail',
        //     query:{
        //         id: 1
        //     }
        // })
         this.$router.push({
            name:"VipDetail",
            query:{
                id:id
            }
        })
        // this.$router.push({path: `/VipManage/VipDetail/${id}`});
    }
  },
};
</script>
<style lang="scss" scoped>
.VipManageAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipManage {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
  .title {
    width: 100%;
    height: 3.125rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        padding: 0.32rem 0.7rem;
        background: #fff;
        border-radius: 0.375rem;
        color: red;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-left: 1.25rem;
        i {
          font-size: 0.65rem;
          margin-right: 0.25rem;
        }
        span {
          font-size: 0.75rem;
        }
      }
      p {
        font-size: 0.8rem;
        font-family: PingFang SC;
        color: #373d49;
        margin-left: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.03rem;
      }
    }
    .right {
      display: flex;
      align-items: center;
      padding-right: 2rem;
      .ShopName {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-family: PingFang SC;
        color: #53565e;
        i {
          font-size: 0.65rem;
          margin-left: 0.35rem;
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        color: #92a5b6;
        padding: 0.3rem;
        background: #f3f6f8;
        border-radius: 50%;
        margin-left: 1.2rem;
      }
    }
  }
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;

  // background: pink;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipManageMain {
  width: 94%;
  margin: 0 auto;
  background: #fff;
  // background: pink;
  .VipNumber {
    width: 97%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 1.65rem 0;
    .content {
      width: 12rem;
      height: 6rem;
      background-image: url(../../../public/images/index/img2.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 2.7rem;
      p {
        font-size: 1rem;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #caaa8a;
      }
      span {
        font-size: 0.75rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        opacity: 0.43;
        margin-top: 0.5rem;
        letter-spacing: 0.03rem;
      }
    }
  }
  .tabRecord {
    width: 97%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 2.2rem;
    background: #f7f8fa;
    font-size: 0.875rem;
    border: 1px solid #e9eaf2;
    .active {
      background: #fff;
      box-sizing: border-box;
      span {
        box-sizing: border-box;
        border-bottom: 0.2rem solid #9da0c2;
      }
      b {
        margin-top: 0.25rem;
        font-weight: normal;
      }
    }
    a {
      width: 4.7rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e9eaf2;
      background: #f0f5fd;
      box-sizing: border-box;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
      }
      b {
        font-weight: normal;
      }
    }
  }
  .tabMain {
    width: 97%;
    margin: 0 auto;
    border: 1px solid #e9eaf2;
    margin-top: 0.75rem;
    padding-bottom: 4rem;
  }
  .tabContent {
    width: 97%;
    margin: 0 auto;
    .nav {
      width: 100%;
      height: 1.95rem;
      display: flex;
      align-items: center;
      background: #f7f8fa;
      span {
        // background: skyblue;
        text-align: center;
        font-size: 0.875rem;
        color: #616060;
      }
    }
    .content {
      width: 100%;
      height: 3.125rem;
      display: flex;
      align-items: center;
      background: #fff;
      border-bottom: 1px solid #fafafc;
      .name {
        width: 22%;
        display: flex;
        align-items: center;
        .tx {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          margin-right: 0.375rem;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
        span {
          flex: 1;
          font-size: 0.875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .money {
        width: 11.3%;
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        justify-content: center;
      }
      .time {
        width: 22%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        color: #999999;
      }
      .number {
        width: 11.3%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          padding: 0.125rem 0.375rem;
          border: 1px solid red;
          font-size: 0.75rem;
          color: red;
        }
      }
      .type {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        .pic {
          width: 1.2rem;
          height: 1.2rem;
          img {
            width: 100%;
          }
        }
      }
      .btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.625rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          padding: 0.25rem 0.58rem;
          background: #3f4156;
          color: #fff;
        }
      }
    }
    .content:nth-child(even) {
      background: #fff;
    } //li的偶数行样式

    .content:nth-child(odd) {
      background: #f7f8fa;
    } //li的奇数行样式
  }
  .pagenation {
    width: 50%;
    height: 3rem;
    float: right;
    margin-top: 1rem;
    // background: pink;
  }
}
</style>