import Vue from 'vue'



// 银行卡每四位加一个空格
Vue.filter("bankcard",function(data){
    var bankcard = null
    bankcard =  data.substr(data.length-4)
    console.log(bankcard);
    return bankcard
})

//银行卡号前12位用*代替
Vue.filter("bankcardnum", function (data) {
    // console.log(data);
    var number = null
    if (data.length >= 11) {
        number = "**** **** **** " + data.substr(-4)
    } else {
        return data
    }
    console.log(number);
    return number

})