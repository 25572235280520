import axios from 'axios'
import router from '../router/index'
const instance = axios.create({
  baseURL: 'https://repast.xinmke.cn/',
  // baseURL: '/apis',
  timeout: 10000,
  // withCredentials: true,
  // headers:{
  //     'Content-Type': 'multipart/form-data'
  // }
})


instance.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = localStorage.getItem('Authorization')
    if (token) {
      console.log(token);
      config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
        console.log("没有token")
        
         router.push( {path: '/login'},
         onComplete => {},
         onAbort => {})
         
      

    }
    return config;
  },
  error => {
    return Promise.reject(error);
  });

export default instance