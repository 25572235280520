<template>
  <div>
    <div class="title">
      <div class="left">
        <a href="javascript:history.back(-1)">
          <i class="iconfont icon-fanhui1"></i>
          <span>返回</span>
        </a>
        <p><slot name="title"></slot></p>
      </div>
      <div class="right">
        <div class="ShopName">
          <span>{{ user.username }}</span>
          <i class="iconfont icon-xiala"></i>
        </div>
        <a
          href="javascript:void(0)"
          class="iconfont icon-lingdang1"
          style="margin-right: 0.77rem;"
        ></a>
      
          <!-- <img
            :src="user.avatar"
            alt="头像"
            v-if="user.avatar != ''"
            style="
              width: 1.58rem;
              height: 1.58rem;
              border-radius: 50%;
            "
          /> -->
          <a href="javascript:void(0)" v-if="user.avatar != ''">
            <img :src="user.avatar" style="
              width: 1.52rem;
              height: 1.52rem;
              border-radius: 50%;
            " /></a>
          <a href="javascript:void(0)" class="iconfont icon-yonghu" v-else></a>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  data() {
    return {
      user: '',
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log(222,this.user);
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 91%;
  height: 3.125rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 9%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .left {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      padding: 0.32rem 0.7rem;
      background: #fff;
      border-radius: 0.375rem;
      color: red;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin-left: 1.25rem;
      i {
        font-size: 0.65rem;
        margin-right: 0.25rem;
      }
      span {
        font-size: 0.75rem;
      }
    }
    p {
      font-size: 0.8rem;
      font-family: PingFang SC;
      color: #373d49;
      margin-left: 0.875rem;
      font-weight: bold;
      letter-spacing: 0.03rem;
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding-right: 2rem;
    .ShopName {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-family: PingFang SC;
      color: #53565e;
      i {
        font-size: 0.65rem;
        margin-left: 0.35rem;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      color: #92a5b6;
      // padding: 0.3rem;
      width: 1.5rem;
      height: 1.5rem;
      background: #f3f6f8;
      border-radius: 50%;
      margin-left: 0.5rem;
    }
  }
}
</style>