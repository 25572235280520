<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>会员卡设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/vipcardsetting">会员卡设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="javascript:void(0)">会员卡充值设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="javascript:void(0)">套餐卡设置</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="main">
            <div class="VipCardSetting_content">
              <ul>
                <li
                  class="VipRechargeSetting"
                  @click="
                    Oneshow = true;
                    Twoshow = false;
                  "
                >
                  <div class="code_left">
                    <div
                      style="
                        width: 1.8rem;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <div
                        class="code_img"
                        style="width: 1.7rem; height: 1.8125rem"
                      >
                        <img
                          src="../../../public/images/index/img24.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <p>会员卡充值设置</p>
                  </div>
                  <i class="iconfont icon-youjiantou"></i>
                </li>
                <li
                  class="vipCardStyle"
                  @click="
                    Twoshow = true;
                    Oneshow = false;
                  "
                >
                  <div class="code_left">
                    <div
                      style="
                        width: 1.8rem;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <div
                        class="code_img"
                        style="width: 1.4rem !important; height: 1.8125rem"
                      >
                        <img
                          src="../../../public/images/index/img25.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <p>套餐卡设置</p>
                  </div>
                  <i class="iconfont icon-youjiantou"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right" v-show="Oneshow">
          <div class="RechargeableCardSetting_main">
            <div
              class="RechargeableCardSetting_content"
              v-for="data in VipRechargeList"
              :key="data.id"
            >
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img26.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">
                    充值{{ data.money }}送{{ data.give_money }}
                  </div>
                  <!-- <div class="Money">卡内余额：¥1888.00</div> -->
                </div>
              </div>
              <div class="btnRight">
                <div @click="handleEditVipMaskShow(data.id)">编辑</div>
                <div @click="handleDelVipMaskShow(data.id)">删除</div>
              </div>
            </div>
          </div>
          <div class="AddRechargeableBtn" @click="VipAddMaskshow = true">
            <div class="icon">
              <img src="../../../public/images/index/img27.png" alt="" />
            </div>
            <span>添加充值卡</span>
          </div>
        </div>
        <van-popup v-model="VipEditMaskshow" round>
          <div class="mask">
            <div class="top">
              <span>编辑充值规则</span>
              <i
                class="iconfont icon-guanbi"
                @click="VipEditMaskshow = false"
              ></i>
            </div>
            <div class="content">
              <p>
                <span>起充金额</span>
                <input
                  type="text"
                  placeholder="请输入金额"
                  v-model="editMinRecharge"
                />
              </p>
            </div>
            <div class="content">
              <p>
                <span>赠送金额</span>
                <input
                  type="text"
                  placeholder="请输入金额"
                  v-model="editGive"
                />
              </p>
            </div>
            <a href="javascript:void(0)" @click="handleEditRecharge">确定</a>
          </div>
        </van-popup>
        <van-popup v-model="VipAddMaskshow" round>
          <div class="mask" style="height: 15.8rem">
            <div class="top">
              <span>添加充值卡</span>
              <i
                class="iconfont icon-guanbi"
                @click="VipAddMaskshow = false"
              ></i>
            </div>
            <!-- <div class="content">
              <p>
                <span>充值名称</span>
                <input type="text" placeholder="请输入充值名称" />
              </p>
            </div> -->
            <div class="content">
              <p>
                <span>起充金额</span>
                <input
                  type="text"
                  placeholder="请输入金额"
                  v-model="addMinRecharge"
                />
              </p>
            </div>
            <div class="content">
              <p>
                <span>赠送金额</span>
                <input type="text" placeholder="请输入金额" v-model="addGive" />
              </p>
            </div>
            <a href="javascript:void(0)" @click="handleAddRecharge">添加</a>
          </div>
        </van-popup>
        <!-- 套餐卡设置 -->
        <div class="right" v-show="Twoshow">
          <div class="RechargeableCardSetting_main">
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">套餐卡1</div>
                  <div class="Money">¥1888.00</div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="handlePackagecardSetting">套餐</div>
                <div>删除</div>
              </div>
            </div>
            <div class="RechargeableCardSetting_content">
              <div class="Left">
                <div class="icon">
                  <img src="../../../public/images/index/img28.png" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">套餐卡2</div>
                  <div class="Money">¥110.00</div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="handlePackagecardSetting">套餐</div>
                <div>删除</div>
              </div>
            </div>
          </div>
          <div class="AddRechargeableBtn">
            <div class="icon">
              <img src="../../../public/images/index/img27.png" alt="" />
            </div>
            <span @click="TaoCanEditMaskshow = true">添加套餐卡</span>
          </div>
        </div>
        <van-popup v-model="TaoCanEditMaskshow" round>
          <div class="mask" style="height: 20rem">
            <div class="top">
              <span>套餐卡设置编辑</span>
              <i
                class="iconfont icon-guanbi"
                @click="TaoCanEditMaskshow = false"
              ></i>
            </div>
            <div class="content">
              <p>
                <span>套餐卡名称</span>
                <input type="text" placeholder="请输入套餐卡名称" />
              </p>
            </div>
            <div class="content">
              <p>
                <span>套餐价格</span>
                <input type="text" placeholder="请输入金额" />
              </p>
            </div>
            <div class="content">
              <p>
                <span>有效期(天)</span>
                <input type="number" placeholder="请输入有效期天数" />
              </p>
            </div>
            <a href="javascript:void(0)" @click="TaoCanEditMaskshow = false"
              >确定</a
            >
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup , Dialog ,Toast} from "vant";
import http from "@/util/http";

Vue.use(Toast);

Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>会员卡设置=>会员卡充值设置
      Oneshow: false,
      //遮罩层(会员卡充值 编辑)
      VipEditMaskshow: false,
      //遮罩层(会员卡充值 添加)
      VipAddMaskshow: false,
      // 首页=>会员卡设置=>套餐卡设置
      Twoshow: false,
      //遮罩层(套餐卡设置 编辑)
      TaoCanEditMaskshow: false,
      // 会员卡充值列表
      VipRechargeList: "",
      // 编辑充值规则--起充金额
      editMinRecharge: "",
      // 编辑充值规则--赠送金额
      editGive: "",
      // 会员卡充值编辑id
      VipEditId: "",
      // 会员卡充值添加--起充金额
      addMinRecharge: "",
      // 会员卡充值添加--赠送金额
      addGive: "",
    };
  },
  mounted() {
    // 充值列表
    http
      .request({
        url: "/shop/indexRechargeList",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.VipRechargeList = res.data.data;
        this.VipEditId = this.VipRechargeList[0].id;
        console.log("充值列表", this.VipRechargeList, this.VipEditId);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    // 前往套餐卡设置页面
    handlePackagecardSetting() {
      this.$router.push({
        path: "/PackagecardSettings",
      });
    },
    // 会员卡充值设置点击编辑
    handleEditVipMaskShow(id) {
      this.VipEditMaskshow = true;
      console.log(id);
      this.VipEditId = id;
      http
        .request({
          url: `/shop/indexRechargeEdit?id=${this.VipEditId}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("编辑会员卡充值11", res.data);
          this.editMinRecharge = res.data.data.money;
          this.editGive = res.data.data.give_money;
        });
    },
    // 会员卡充值设置点击编辑--点击确定
    handleEditRecharge() {
      this.VipEditMaskshow = false;

      http
        .request({
          url: "/shop/indexRechargeEdit",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            id: this.VipEditId,
            money: this.editMinRecharge,
            give_money: this.editGive,
          },
        })
        .then((res) => {
          console.log("编辑会员卡充值", res.data);
          http
            .request({
              url: "/shop/indexRechargeList",
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.VipRechargeList = res.data.data;
              console.log("充值列表", this.VipRechargeList);
            });
          Toast(res.data.msg);
        });
    },
    // 添加充值卡
    handleAddRecharge() {
      this.VipAddMaskshow = false;

      http
        .request({
          url: "/shop/indexRechargeAdd",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            money: this.addMinRecharge,
            give_money: this.addGive,
          },
        })
        .then((res) => {
          console.log("添加会员卡充值", res.data);
          http
            .request({
              url: "/shop/indexRechargeList",
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.VipRechargeList = res.data.data;
              console.log("充值列表", this.VipRechargeList);
            });
          Toast(res.data.msg);
        });
    },
    // 删除vip充值卡
    handleDelVipMaskShow(id) {
      Dialog.confirm({
  title: '您确定删除此数据吗?',
})
  .then(() => {
    // on confirm
     http
        .request({
          url: `/shop/indexRechargeDel?id=${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("删除会员卡", res.data);
          http
            .request({
              url: "/shop/indexRechargeList",
              method: "get",
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              this.VipRechargeList = res.data.data;
              console.log("充值列表", this.VipRechargeList);
            });
          Toast(res.data.msg);
        });
  })
  .catch(() => {
    // on cancel
    console.log("点击取消")
  });
     
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    .main {
      display: flex;
      flex-direction: column;
      .content {
        width: 86%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftContent {
          display: flex;
          align-items: center;
          .img {
            width: 2.5625rem;
            height: 1.8125rem;
            img {
              width: 100%;
            }
          }
          span {
            font-size: 0.875rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}
// 左边
.VipCardSetting_content {
  width: 100%;
  /* margin-top: 0.77rem; */
  background: #fff;
}
.VipCardSetting_content ul {
  width: 80%;
  margin: 0 auto;
}

.VipCardSetting_content ul li {
  height: 3.625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  border-bottom: 1px solid #f3f3f3;
}
.VipCardSetting_content ul li .code_left {
  width: 45%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* background:pink; */
}
.VipCardSetting_content ul li .code_left .code_img {
  /* width: 1.4rem; */
  /* margin-right: 0.77rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: skyblue; */
}
.VipCardSetting_content ul li .code_left .code_img img {
  width: 100%;
}

.VipCardSetting_content ul li .code_left p {
  margin-left: 1rem;
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}
.RechargeableCardSetting_main {
  height: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 5rem;
}
.RechargeableCardSetting_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
}
.RechargeableCardSetting_content .Left {
  width: 62%;
  /* background: skyblue; */
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .Left .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.55rem;
  display: inline-block;
  flex-shrink: 0;
}
.RechargeableCardSetting_content .Left .icon img {
  width: 100%;
}
.RechargeableCardSetting_content .Left .info {
  flex: 1;
  /* background: orchid; */
}
.RechargeableCardSetting_content .Left .info .Notice {
  width: 10rem;
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.95rem;
}
.RechargeableCardSetting_content .Left .info .Money {
  width: 10rem;
  margin-top: 0.3rem;
  font-size: 0.75rem;
  color: #ff878b;
  /* margin-top: 0.18rem; */
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RechargeableCardSetting_content .btnRight {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .btnRight div {
  width: 4rem;
  height: 1.65rem;
  border: 1px solid #d8d8dc;
  font-size: 0.85rem;
  margin-left: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.AddRechargeableBtn {
  width: 100%;
  height: 4.5rem;
  border-top: 0.1875rem solid #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  margin-top: 4rem;
}
.AddRechargeableBtn .icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.55rem;
}
.AddRechargeableBtn img {
  width: 100%;
}
.mask {
  width: 19.125rem;
  height: 15.75rem;
  background: #f2f2f2;
  border-radius: 1.25rem;
  .top {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    background: #fff;
    letter-spacing: 0.03rem;
    margin-bottom: 0.7rem;
    i {
      position: absolute;
      right: 1rem;
      font-size: 1.3rem;
      color: #999999;
    }
  }
  .content {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f7f7f7;
    p {
      width: 88%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 0.9rem;
        font-weight: bold;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        flex: 1;
        margin-left: 1rem;
        height: 2.5rem;
        border: none;
        font-size: 0.9rem;
        text-align: left;
      }
    }
  }
  a {
    width: 80%;
    margin: 0 auto;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fa4347;
    font-size: 0.95rem;
    color: #fff;
    border-radius: 1.25rem;
    margin-top: 2.2rem;
    letter-spacing: 0.2rem;
  }
}
</style>