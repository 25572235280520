<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>云店管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/YunDianManage">云店管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="javascript:void(0)" @click="handlePageGoodsManage"
              >商品管理</a
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="javascript:void(0)">产品规格</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="RechargeableCardSetting_main">
            <div
              class="RechargeableCardSetting_content"
              v-for="data in datalist"
              :key="data.id"
            >
              <div class="Left">
                <div class="icon">
                  <img :src="data.image" alt="" />
                </div>
                <div class="info">
                  <div class="Notice">{{ data.title }}</div>
                  <div class="Money">¥{{ data.price }}</div>
                </div>
              </div>
              <div class="btnRight">
                <div @click="handleEdit(data.id)">编辑</div>
                <div @click="handleDel(data.id)">删除</div>
              </div>
            </div>
          </div>
          <div class="AddRechargeableBtn">
            <div class="icon">
              <img src="../../../public/images/index/img27.png" alt="" />
            </div>
            <span @click="handleAdd">添加产品规格</span>
          </div>
        </div>
        <div class="right" v-show="Oneshow">
          <div class="GoodsPic">
            <p>规格缩略图</p>
            <div class="pic">
              <!-- <van-uploader
                :after-read="afterRead"
                multiple
                v-model="fileList"
                :preview-full-image="false"
                :max-count="6"
                @delete="afterDel"
                :deletable="false"
              /> -->
              <div class="piclist">
                <img
                  :src="data"
                  alt=""
                  v-for="(data, index) in imagelist"
                  :key="data"
                  @click="handleClickPic(data, index)"
                />

                <a
                  href="javascript:void(0)"
                  class="imageFileInput"
                  v-show="imagelist.length < 1"
                >
                  <input
                    class="fileInput"
                    type="file"
                    multiple="multiple"
                    @change="handleChooseImg"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="GoodsInfo">
            <div class="content">
              <p>规格标题</p>
              <input type="text" placeholder="请输入规格标题" v-model="title" />
            </div>
            <div class="content">
              <p>价格</p>
              <input type="text" placeholder="请输入价格" v-model="price" />
            </div>
            <div class="content">
              <p>库存</p>
              <input type="text" placeholder="请输入库存" v-model="stock" />
            </div>
            <div class="content">
              <p>销量</p>
              <input type="text" placeholder="请输入销量" v-model="saleNum" />
            </div>
            <div class="content">
              <p>排序</p>
              <input type="text" placeholder="请输入排序序号" v-model="sort" />
            </div>
            <!-- <div class="content">
              <p>商品库存</p>
              <input type="text" placeholder="不填为不限量" />
            </div> -->
          </div>
          <div class="status">
            <div class="content">
              <p>是否开启</p>
              <van-switch v-model="checked" size="24px" />
            </div>
            <!-- <div class="content">
                  <p>推荐商品</p>
                  <van-switch v-model="checked" size="24px" />
              </div> -->
          </div>
          <div
            class="btn"
            style="display: flex; justify-content: center; align-items: center"
          >
            <!-- <a href="javascript:void(0)" class="DelBtn">
            删除规格
          </a> -->
            <a
              href="javascript:void(0)"
              class="SaveBtn"
              style="margin-right: 0"
              @click="handleSaveBtn"
            >
              提交保存
            </a>
          </div>
        </div>
        <!-- 订单管理 -->
        <div class="right" v-show="Twoshow">
          <div class="GoodsPic">
            <p>编辑编辑编辑编辑规格缩略图</p>
            <div class="pic">
              <!-- <van-uploader
                :after-read="afterRead"
                multiple
                v-model="fileList"
                :preview-full-image="false"
                :max-count="6"
                @delete="afterDel"
                :deletable="false"
              /> -->
              <div class="piclist">
                <img
                  :src="data"
                  alt=""
                  v-for="(data, index) in Newimagelist"
                  :key="data"
                  @click="handleEditClickPic(data, index)"
                />

                <a
                  href="javascript:void(0)"
                  class="imageFileInput"
                  v-show="Newimagelist.length < 1"
                >
                  <input
                    class="fileInput"
                    type="file"
                    multiple="multiple"
                    @change="handleEditChooseImg"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="GoodsInfo">
            <div class="content">
              <p>规格标题</p>
              <input
                type="text"
                placeholder="请输入规格标题"
                v-model="Newtitle"
              />
            </div>
            <div class="content">
              <p>价格</p>
              <input type="text" placeholder="请输入价格" v-model="Newprice" />
            </div>
            <div class="content">
              <p>库存</p>
              <input type="text" placeholder="请输入库存" v-model="Newstock" />
            </div>
            <div class="content">
              <p>销量</p>
              <input
                type="text"
                placeholder="请输入销量"
                v-model="NewsaleNum"
              />
            </div>
            <div class="content">
              <p>排序</p>
              <input
                type="text"
                placeholder="请输入排序序号"
                v-model="Newsort"
              />
            </div>
            <!-- <div class="content">
              <p>商品库存</p>
              <input type="text" placeholder="不填为不限量" />
            </div> -->
          </div>
          <div class="status">
            <div class="content">
              <p>是否开启</p>
              <van-switch v-model="Newchecked" size="24px" />
            </div>
            <!-- <div class="content">
                  <p>推荐商品</p>
                  <van-switch v-model="checked" size="24px" />
              </div> -->
          </div>
          <div
            class="btn"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a href="javascript:void(0)" class="DelBtn" @click="handleDelSpecs">
              删除规格
            </a>
            <a
              href="javascript:void(0)"
              class="SaveBtn"
              style="margin-left: 1.5rem"
              @click="handleEditSave"
            >
              确定修改
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import {
  Popup,
  Dialog,
  Toast,
  Pagination,
  Empty,
  Icon,
  Circle,
  Tab,
  Tabs,
} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Circle);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 编辑分类
      Oneshow: false,
      // 添加分类
      Twoshow: false,
      //   产品id
      id: "",
      //  商品图片
      fileList: [],
      //   商品状态
      checked: true,
      // 产品名称
      title: "",
      // 产品价格
      price: "",
      //   商品状态
      checked: true,
      // 上传网络图片列表
      imagelist: [],
      // 产品库存
      stock: "",
      // 产品销量
      saleNum: "",
      //   产品排序
      sort: "",
      // 产品最终状态
      open: 0,
      // 产品选择分类显示文字
      imgUrl: "",
      //   产品规格列表
      datalist: "",
      //   上传图片链接
      image_path: "",

      //   编辑规格！！！！！！！！！！！！！！！！！！！！！！！！！
      //  编辑商品图片
      NewfileList: [],
      //   编辑商品状态
      Newchecked: true,
      // 编辑产品名称
      Newtitle: "",
      // 编辑产品价格
      Newprice: "",
      //   编辑商品状态
      Newchecked: true,
      // 编辑上传网络图片列表
      Newimagelist: [],
      // 编辑产品库存
      Newstock: "",
      // 编辑产品销量
      NewsaleNum: "",
      //   编辑产品排序
      Newsort: "",
      // 编辑产品最终状态
      Newopen: 0,
      // 编辑产品选择分类显示文字
      NewimgUrl: "",
      //   编辑上传图片链接
      Newimage_path: "",
      //   规格修改查询
      EditInfo: "",
      //   当前编辑规格id
      specsId: "",
    };
  },
  mounted() {
    // this.Oneshow = this.$route.params.Oneshow;
    // console.log(this.Oneshow);
    this.id = this.$route.query.id;
    console.log("id", this.id);
    this.handleGetList();
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   显示商品管理页面
    handleShowGoodsManage() {
      this.Twoshow = false;
      this.Oneshow = true;
    },
    // 显示订单管理页面
    handleShowOrderManage() {
      this.Oneshow = false;
      this.Twoshow = true;
    },
    handleChooseImg(e) {
      var that = this;
      var img = e.target.files[0];
      console.log(img);
      var reader = new FileReader();
      reader.readAsDataURL(img);
      // console.log(reader)

      reader.onload = function (e) {
        console.log("base64", e);
        var url = e.target.result;

        http
          .request({
            url: "/shop/yundianUploadImg",
            method: "post",
            headers: {
              // "Content-Type": "application/json",
              "Content-Type": " multipart/form-data",
            },
            data: {
              img: url,
            },
          })
          .then((res) => {
            console.log("上传图片", res.data.data.image_path);
            // console.log(987, this.fileList);
            Toast(res.data.msg);
            that.imagelist.push(res.data.data.image_path);
            that.image_path = res.data.data.image_path;
            console.log("图片列表", that.imagelist);
            console.log("图片链接", that.image_path);
          });
      };
    },
    // 跳转回商品管理页面
    handlePageGoodsManage() {
      this.$router.push({
        name: "YunDianManage",
        params: {
          Oneshow: true,
        },
      });
    },
    // 删除图片
    handleClickPic(data, index) {
      console.log(data, index);
      Dialog.confirm({
        title: "确定要删除此图片吗",
      })
        .then(() => {
          // on confirm
          this.imagelist.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    handleGetList() {
      var id = this.id;
      http
        .request({
          url: `/shop/yundianGoodsSku?id=${id}`,
          method: "get",
          headers: {
            // "Content-Type": "application/json",
            //   "Content-Type": " multipart/form-data",
          },
        })
        .then((res) => {
          this.datalist = res.data.data;
          console.log("规格列表", this.datalist);
        });
    },
    handleSaveBtn() {
      if (
        this.imagelist == [] ||
        this.imagelist == "" ||
        this.imagelist == "undefined" ||
        this.imagelist == null
      ) {
        Toast("请上传规格缩略图");
        return;
      } else if (this.title == "") {
        Toast("请输入规格标题");
        return;
      } else if (this.price == "") {
        Toast("请输入价格");
        return;
      } else if (this.stock == "") {
        Toast("请输入库存");
        return;
      } else if (this.saleNum == "") {
        Toast("请输入销量");
        return;
      } else if (this.sort == "") {
        Toast("请输入规格排序");
        return;
      }

      if (this.checked == true) {
        this.open = 1;
      } else {
        this.open = 0;
      }
      http
        .request({
          url: "/shop/yundianGoodsSkuAdd",
          method: "post",
          headers: {
            // "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
          },
          data: {
            yundian_id: this.id,
            title: this.title,
            price: this.price,
            stoke: this.stock,
            open: this.open,
            image: this.image_path,
            sale: this.saleNum,
            sort: this.sort,
          },
        })
        .then((res) => {
          console.log("点击提交保存", res);
          if (res.data.code == 1) {
            this.Oneshow = false;
            this.handleGetList();
            Toast(res.data.msg);
            this.title = "";
            this.price = "";
            this.stock = "";
            this.checked = true;
            this.image_path = "";
            this.imagelist = [];
            this.saleNum = "";
            this.sort = "";
          } else {
            Toast(res.data.msg);
          }
        });
    },
    handleAdd() {
      this.Twoshow = false;
      this.Oneshow = true;
    },
    // 规格修改查询
    handleEdit(id) {
      this.Oneshow = false;
      this.Twoshow = true;
      this.Newimagelist = [];
      http
        .request({
          url: `/shop/yundianGoodsSkuEdit?id=${id}`,
          method: "get",
          headers: {
            // "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.EditInfo = res.data.data;
          console.log("规格修改查询", this.EditInfo);
          this.Newimage_path = res.data.data.image;
          this.Newtitle = this.EditInfo.title;
          this.Newprice = this.EditInfo.price;
          this.Newstock = this.EditInfo.stoke;
          this.NewsaleNum = this.EditInfo.sale;
          this.Newsort = res.data.data.sort;
          this.Newimagelist.push(res.data.data.image);
          this.specsId = res.data.data.id;
          console.log(this.Newimagelist);

          if (this.EditInfo.open == 0) {
            this.Newchecked = false;
          } else {
            this.Newchecked = true;
          }
        });
    },
    handleEditChooseImg(e) {
      var that = this;
      var img = e.target.files[0];
      console.log(img);
      var reader = new FileReader();
      reader.readAsDataURL(img);
      // console.log(reader)

      reader.onload = function (e) {
        console.log("base64", e);
        var url = e.target.result;

        http
          .request({
            url: "/shop/yundianUploadImg",
            method: "post",
            headers: {
              // "Content-Type": "application/json",
              "Content-Type": " multipart/form-data",
            },
            data: {
              img: url,
            },
          })
          .then((res) => {
            console.log("上传图片", res.data.data.image_path);
            // console.log(987, this.fileList);
            Toast(res.data.msg);
            that.Newimagelist.push(res.data.data.image_path);
            that.Newimage_path = res.data.data.image_path;
            console.log("图片列表", that.Newimagelist);
            console.log("图片链接", that.Newimage_path);
          });
      };
    },
    // 删除图片
    handleEditClickPic(data, index) {
      console.log(data, index);
      Dialog.confirm({
        title: "确定要删除此图片吗",
      })
        .then(() => {
          // on confirm
          this.Newimagelist.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 确定修改
    handleEditSave() {
      console.log(this.Newsort);
      if (
        this.Newimagelist == [] ||
        this.Newimagelist == "" ||
        this.Newimagelist == "undefined" ||
        this.Newimagelist == null
      ) {
        Toast("请上传规格缩略图");
        return;
      } else if (this.Newtitle == "") {
        Toast("请输入规格标题");
        return;
      } else if (this.Newprice == "") {
        Toast("请输入价格");
        return;
      } else if (this.Newstock == "") {
        Toast("请输入库存");
        return;
      } else if (this.NewsaleNum == "") {
        Toast("请输入销量");
        return;
      } else if (this.Newsort == "") {
        Toast("请输入规格排序");
        return;
      }

      if (this.Newchecked == true) {
        this.Newopen = 1;
      } else {
        this.Newopen = 0;
      }
      http
        .request({
          url: "/shop/yundianGoodsSkuEdit",
          method: "post",
          headers: {
            // "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
          },
          data: {
            yundian_id: this.id,
            title: this.Newtitle,
            price: this.Newprice,
            stoke: this.Newstock,
            open: this.Newopen,
            image: this.Newimage_path,
            sale: this.NewsaleNum,
            sort: this.Newsort,
            id: this.specsId,
          },
        })
        .then((res) => {
          console.log("点击确认修改", res);
          if (res.data.code == 1) {
            this.Twoshow = false;
            this.handleGetList();
            Toast(res.data.msg);
            this.Newtitle = "";
            this.Newprice = "";
            this.Newstock = "";
            this.Newchecked = true;
            this.Newimage_path = "";
            this.Newimagelist = [];
            this.NewsaleNum = "";
            this.Newsort = "";
          } else {
            Toast(res.data.msg);
          }
        });
    },
    // 编辑页面删除规格
    handleDelSpecs() {
      console.log(this.specsId);
      Dialog.confirm({
        title: "确定要删除此商品吗",
      })
        .then(() => {
          http
            .request({
              url: "/shop/yundianGoodsSkuDel",
              method: "post",
              headers: {
                // "Content-Type": "application/json",
                //   "Content-Type": " multipart/form-data",
              },
              data: {
                id: this.specsId,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                Toast(res.data.msg);
                this.Twoshow = false;
                this.handleGetList();
              } else {
                Toast(res.data.msg);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除规格
    handleDel(id) {
      Dialog.confirm({
        title: "确定要删除此商品吗",
      })
        .then(() => {
          http
            .request({
              url: "/shop/yundianGoodsSkuDel",
              method: "post",
              headers: {
                // "Content-Type": "application/json",
                //   "Content-Type": " multipart/form-data",
              },
              data: {
                id: id,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                Toast(res.data.msg);
                this.Twoshow = false;
                this.handleGetList();
              } else {
                Toast(res.data.msg);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
// 左右内容
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    background: #fff;
    position: relative;
  }
  // 左边
  .RechargeableCardSetting_main {
    height: 45rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    //   justify-content: center;
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 5rem;
  }
  .RechargeableCardSetting_content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
  }
  .RechargeableCardSetting_content .Left {
    width: 62%;
    /* background: skyblue; */
    display: flex;
    align-items: center;
  }
  .RechargeableCardSetting_content .Left .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 0.55rem;
    display: inline-block;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .RechargeableCardSetting_content .Left .icon img {
    // width: 100%;
    width: 4rem;
    height: 4rem;
  }
  .RechargeableCardSetting_content .Left .info {
    flex: 1;
    /* background: orchid; */
  }
  .RechargeableCardSetting_content .Left .info .Notice {
    width: 10rem;
    /* background: orange; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.85rem;
  }
  .RechargeableCardSetting_content .Left .info .Money {
    width: 10rem;
    margin-top: 1rem;
    font-size: 0.75rem;
    color: #ff878b;
    /* margin-top: 0.18rem; */
    /* background: orange; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .RechargeableCardSetting_content .btnRight {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .RechargeableCardSetting_content .btnRight div {
    width: 4rem;
    height: 1.65rem;
    border: 1px solid #d8d8dc;
    font-size: 0.85rem;
    margin-left: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
  }
  .AddRechargeableBtn {
    width: 100%;
    height: 4.5rem;
    border-top: 0.1875rem solid #f4f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: #fff;
    margin-top: 4rem;
  }
  .AddRechargeableBtn .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.55rem;
  }
  .AddRechargeableBtn img {
    width: 100%;
    width: 1rem;
    height: 1rem;
  }
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}
.GoodsPic {
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   flex-direction: column;
  //   background: pink;
  background: #fff;
  border-bottom: 1px solid #f7f7f7;

  p {
    flex: 1;
    margin: 0 auto;
    height: 2.8rem;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    // background: cornflowerblue;
  }
  .pic {
    // width: 96%;
    width: 5rem;
    margin: 0 auto;
    margin-top: 0.5rem;
    // background: chocolate;
  }
}
.GoodsInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 1rem;
  .content {
    width: 96%;
    height: 2.8rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
    p {
      font-size: 0.95rem;
    }
    input {
      height: 1.8rem;
      width: 50%;
      border: none;
      text-align: right;
      font-size: 0.95rem;
    }
    span {
      font-size: 0.95rem;
    }
  }
}
.status {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  //   margin-top: 1rem;
  .content {
    width: 96%;
    height: 2.8rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
    p {
      font-size: 0.95rem;
    }
  }
}
// .SaveBtn {
//   width: 100%;
//   height: 2.8rem;
//   background: #f93e3d;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   font-size: 0.95rem;
//   position: absolute;
//   bottom: 0;
//   left: 0;
// }

.VipcardSettingMain .right .pic {
  // background: pink;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  .piclist {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    img {
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}

/*定义图像以及大小*/
.imageFileInput {
  width: 5rem;
  height: 5rem;
  // position: absolute;
  background-image: url(../../../public/images/index/img64.png); /*这里可以换成图片路径（background-image：../img/....）注意图片路径*/
  // background-repeat: no-repeat;
}

/*定义上传*/
.fileInput {
  width: 5rem;
  height: 100%;
  // position: absolute;
  // right: 0;
  // top: 0;
  opacity: 0;
}

.btn {
  width: 100%;
  height: 3.8rem;
  // background: #f93e3d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .DelBtn {
    width: 10rem;
    height: 2.4rem;
    background: #ff9800;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    margin-left: 8rem;
    border-radius: 0.5rem;
  }
  .SaveBtn {
    width: 10rem;
    height: 2.4rem;
    background: #f93634;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    margin-right: 8rem;
    border-radius: 0.5rem;
  }
}
</style>