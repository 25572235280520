<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>二维码设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/QRCodeSetting">二维码设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="/#/YunDianQrCode">云店二维码</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-show="Oneshow == true"><a href="javascript:void(0)">云店二维码详情</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
           <div class="CloudStoreCode_circle">
            <!-- <van-circle
              v-model="currentRateThree"
              :rate="rate"
              :speed="100"
              :text="textThree"
              layer-color="#ffe7a6"
              color="#fff"
              :stroke-width="80"
            ></van-circle> -->
            <div
              style="
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                border: 0.4rem solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                opacity: 0.85;
              "
            >
              66
            </div>
            <p>当前分店二维码（张）</p>
          </div>
          <div class="main" style="flex: 1; overflow: scroll">

          <div
            class="CloudStoreCode_content"
            @click="Oneshow= true"
          >
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img38.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          </div>
        </div>
        <!-- 收款码 -->
        <div class="right" v-show="Oneshow">
          <div class="CloudStoreQRCode_main">
            <div class="CloudStoreQRCode_content" @click="show = true">
                <p>图片:</p>
                <div class="CloudStoreQRCode_content_img">
                    <img src="../../../public/images/index/img46.png" alt="">
                </div>
                <i class="iconfont icon-youjiantou"></i>
            </div>
            <div class="CloudStoreQRCode_content">
                <p>类型:</p>
                <span>店铺二维码</span>
            </div>
            <div class="CloudStoreQRCode_content">
                <p>编号:</p>
                <span>8454001000006041963</span>
            </div>
            <div class="CloudStoreQRCode_content">
                <p>生成时间:</p>
                <span>2021-03-17</span>
            </div>
            <div class="CloudStoreQRCode_content" style="border:none">
                <p>所属店铺:</p>
                <span>车管家大剧院店</span>
            </div>
        </div>
        </div>
       <van-overlay :show="show" @click="show = false">
            <div class="CloudStoreQRCode_Mask">
                <img src="../../../public/images/index/img46.png" alt="">
                <div class="CloudStoreQRCode_Mask_codeimg">
                    <img src="../../../public/images/index/img47.png" alt="">
                </div>
                <div class="CloudStoreQRCode_Mask_notice">
                    <p>车管家南坊店</p>
                </div>
                <i class="iconfont icon-guanbi"></i>
            </div>
           
            <a href="javascript:void(0)" class="CloudStoreQRCode_mask_btn">
                保存收款码
            </a>
        </van-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup, Dialog, Toast, Circle ,Overlay} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Circle);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>二维码设置=>收款码
      Oneshow: false,
      // 首页=>二维码设置=>员工码
      Threeshow: false,
      //   收款码数量
      currentRate: 0,
      //   遮罩层图片
      show: false,
    };
  },
  mounted() {},
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   跳转收款码子页面
    handlePageCollectionCode() {
      this.$router.push({
        path: "/CollectionCode",
      });
    },
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain .left ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #807373;
    margin-right: 5rem;
    display: flex;
    flex-direction: column;
    background: #fff;
     display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  }

  // 左边
  .CloudStoreCode_circle {
  width: 100%;
  /* height: 8.8rem; */
  background-color: #fd800c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
  margin-bottom: 0.77rem;
}

.CloudStoreCode_circle p {
  font-size: 0.85rem;
  color: #fff;
  margin-top: 0.77rem;
}
#CloudStoreCode .van-circle__text {
  color: #fff !important;
  font-size: 1.2rem !important;
}
.CloudStoreCode_content {
  width: 94%;
  height: 6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-top: 0.3rem;
}
.CloudStoreCode_content_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.CloudStoreCode_content_img {
  width: 2.5rem;
  height: 4.0625rem;
  margin-left: 0.6rem;
}
.CloudStoreCode_content_img img {
  width: 100%;
}
.CloudStoreCode_content_info {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
}
.CloudStoreCode_content_info p {
  margin-bottom: 0.3rem;
  width: 14rem;
  display: flex;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info p i {
  width: 100%;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info span {
  font-size: 0.8rem;
  color: #bfbdbd;
  width: 16.5rem;
  display: block;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info b {
  font-weight: normal;
}
.CloudStoreCode_content_right {
  color: #a9a9a9;
  margin-right: 0.77rem;
}
.CloudStoreCode_btn {
  display: block;
  width: 80%;
  height: 2.1rem;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  margin: 0 auto;
  margin-top: 4.7rem;
  line-height: 2.1rem;
  text-align: center;
  border-radius: 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
}
}

// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}

  .CloudStoreQRCode_main{
            width: 95%;
            margin: 0 auto;
            margin-top: 3.8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.77rem;
            border-radius: 0.25rem;
            box-shadow: 0 2px 0px rgba(0, 0, 0, .04), 0 0 4px rgba(0, 0, 0, .04)
          }
          .CloudStoreQRCode_content{
            width: 95%;
            display: flex;
            height: 3.8rem;
            background-color: #fff;
            margin: 0 auto;
            font-size: 0.9rem;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
          }
          .CloudStoreQRCode_content_img{
            width: 2.5rem;
            height: 4.0625rem;
          }
          .CloudStoreQRCode_content p{
            width: 5.5rem;
            letter-spacing: 0.08rem;
          }
          .CloudStoreQRCode_content_img img{
            width: 100%;
          }
          .CloudStoreQRCode_content i{
            position: absolute;
            right: 1rem;
            font-size: 1.1rem;
            color: #c7c7c7;
            font-weight: bold;
          }
          .CloudStoreQRCode_content span{
              width: 70%;
              height: 100%;
              display: block;
              line-height: 3.8rem;
              font-size: 0.9rem;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
          }
          .CloudStoreQRCode_Mask{
            width: 18rem;
            height: 23.375rem;
            margin: 0 auto;
            margin-top: 6.6rem;
            position: relative;
          }
          .CloudStoreQRCode_Mask img{
            width: 100%;
          }
          .CloudStoreQRCode_mask_btn{
            display: block;
            width: 20rem;
            height: 2.2rem;
            margin: 0 auto;
            margin-top: 8rem;
            line-height: 2.2rem;
            text-align: center;
            background: linear-gradient(to right, #fc4a30, #fa2051);
            border-radius: 1.25rem;
            font-size: 0.9rem;
            letter-spacing: 0.08rem;
            color: #fff;
            margin-bottom: 1.8rem;
          }
          .CloudStoreQRCode_Mask_codeimg{
            width: 3.8rem;
            height: 3.8rem;
            position: absolute;
            left: 60%;
            top: 103%;
          }
          .CloudStoreQRCode_Mask_codeimg img{
            width: 100%;
          }
          .CloudStoreQRCode_Mask_notice{
            width: 19rem;
            height: 1.5rem;
            line-height: 1.5rem;
            display: flex;
            font-size: 0.7rem;
            color: #fff;
            /* background-color: orangered; */
            position: absolute;
            left: 9%;
            top: 112%;
            transform:scale(0.9);
            /* text-align: center; */
          
          }
          .CloudStoreQRCode_Mask_notice p{
            width: 46%;
            font-size: 0.8rem;
            letter-spacing: 0.08rem;
            /* background-color: skyblue; */
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            color: rgb(243, 240, 240);
          }
          .CloudStoreQRCode_Mask i{
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-weight: bold;
          }
</style>