<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>二维码设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/QRCodeSetting">二维码设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="/#/StaffCode">员工码</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="javascript:void(0)">员工码详情</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="StaffQRCode_circle">
            <!-- <van-circle
              v-model="currentRate"
              :rate="54"
              :speed="100"
              :text="text"
              layer-color="#ffe7a6"
              color="#fff"
              :stroke-width="80"
            ></van-circle> -->
            <div
              style="
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                border: 0.4rem solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                opacity: 0.85;
              "
            >
              {{ staffcodelist.code_num }}
            </div>
            <p>员工二维码数量（张）</p>
          </div>
          <div class="main" style="flex: 1; overflow: scroll">
            <div
              class="StaffQRCode_content"
              v-for="data in staffcodelist.list"
              :key="data.id"
              @click="handleDetail(data.id)"
            >
              <div class="StaffQRCode_content_left">
                <div class="StaffQRCode_content_img">
                  <img src="../../../public/images/index/img37.png" alt="" />
                </div>
                <div class="StaffQRCode_content_info">
                  <p>
                    员工姓名：<i>{{ data.uname }}</i>
                  </p>
                  <span
                    >收款编号：<b>{{ data.job_number }}</b></span
                  >
                </div>
              </div>
              <div class="StaffQRCode_content_right">
                <i class="iconfont icon-youjiantou"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 收款码 -->
        <div class="right" v-show="Oneshow">
          <div class="StaffCode_main">
            <div class="StaffCode_content" @click="show = true">
              <p>图片:</p>
              <div class="StaffCode_content_img">
                <img src="../../../public/images/index/img43.png" alt="" />
              </div>
              <i class="iconfont icon-youjiantou"></i>
            </div>
            <div class="StaffCode_content">
              <p>员工姓名:</p>
              <span>马云</span>
            </div>
            <div class="StaffCode_content">
              <p>员工电话:</p>
              <span>18888888888</span>
            </div>
            <div class="StaffCode_content" style="border: none">
              <p>添加时间:</p>
              <span>2021-03-17 16:18:07</span>
            </div>
          </div>
        </div>
        <van-overlay :show="show" @click="show = false">
          <div class="StaffCode_Mask">
            <img src="../../../public/images/index/img44.png" alt="" />
            <div class="StaffCode_Mask_codeimg">
              <img src="../../../public/images/index/img45.png" alt="" />
            </div>
            <div class="StaffCode_Mask_notice">
              <p>车管家南坊店</p>
            </div>
            <i class="iconfont icon-guanbi"></i>
          </div>

          <a href="javascript:void(0)" class="StaffCode_mask_btn">
            保存员工收款码
          </a>
        </van-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup, Dialog, Toast, Circle, Overlay } from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Circle);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>二维码设置=>收款码
      Oneshow: false,
      // 首页=>二维码设置=>员工码
      Threeshow: false,
      //   收款码数量
      currentRate: 0,
      //   遮罩层图片
      show: false,
      // 员工二维码列表
      staffcodelist: "",
      // 员工id
      id: "",
    };
  },
  mounted() {
    // 员工码数据列表
    http
      .request({
        url: "/shop/indexStaffCodeList",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.staffcodelist = res.data.data;
        console.log(77, this.staffcodelist);
        this.id = this.staffcodelist.list[0].id;
        console.log(this.id);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   跳转收款码子页面
    handlePageCollectionCode() {
      this.$router.push({
        path: "/CollectionCode",
      });
    },
    // 员工码详情
    handleDetail(id) {
      this.Oneshow = true;
      this.id = id;
    },
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain .main::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #807373;
    margin-right: 5rem;
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  // 左边
  .StaffQRCode_circle {
    width: 100%;
    /* height: 8.8rem; */
    background-color: #ffd24d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 0;
    margin-bottom: 0.77rem;
    display: flex;
    flex-direction: column;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .StaffQRCode_circle p {
    font-size: 0.85rem;
    color: #000;
    margin-top: 0.77rem;
  }
  .van-circle__text {
    color: #000 !important;
    font-size: 1.2rem !important;
  }
  .StaffQRCode_content {
    width: 94%;
    height: 6rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    margin-top: 0.3rem;
  }
  .StaffQRCode_content_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .StaffQRCode_content_img {
    width: 3rem;
    height: 3rem;
    margin-left: 0.35rem;
  }
  .StaffQRCode_content_img img {
    width: 100%;
  }
  .StaffQRCode_content_info {
    margin-left: 0.5rem;
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
  }
  .StaffQRCode_content_info p {
    margin-bottom: 0.3rem;
    width: 12rem;
    display: flex;
    /* background-color: pink; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .StaffQRCode_content_info p i {
    width: 100%;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .StaffQRCode_content_info span {
    font-size: 0.8rem;
    color: #bfbdbd;
    width: 16.5rem;
    display: block;
    /* background-color: pink; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .StaffQRCode_content_info b {
    font-weight: normal;
  }
  .StaffQRCode_content_right {
    color: #a9a9a9;
    margin-right: 0.77rem;
  }
  .StaffQRCode_btn {
    display: block;
    width: 80%;
    height: 2.1rem;
    background: linear-gradient(to right, #fc4a30, #fa2051);
    margin: 0 auto;
    margin-top: 4.7rem;
    line-height: 2.1rem;
    text-align: center;
    border-radius: 1.25rem;
    color: #fff;
    font-size: 0.9rem;
    letter-spacing: 0.08rem;
  }
}

// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}

.StaffCode_main {
  width: 95%;
  margin: 0 auto;
  margin-top: 3.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.77rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.04);
}
.StaffCode_content {
  width: 95%;
  display: flex;
  height: 3.8rem;
  background-color: #fff;
  margin: 0 auto;
  font-size: 0.9rem;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.StaffCode_content_img {
  width: 2.625rem;
  height: 2.625rem;
}
.StaffCode_content p {
  width: 5.5rem;
  letter-spacing: 0.08rem;
}
.StaffCode_content_img img {
  width: 100%;
}
.StaffCode_content i {
  position: absolute;
  right: 1rem;
  font-size: 1.1rem;
  color: #c7c7c7;
  font-weight: bold;
}
.StaffCode_content span {
  width: 70%;
  height: 100%;
  display: block;
  line-height: 3.8rem;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StaffCode_Mask {
  width: 18rem;
  height: 23.375rem;
  margin: 0 auto;
  margin-top: 10.8rem;
  position: relative;
}
.StaffCode_Mask img {
  width: 100%;
}
.StaffCode_mask_btn {
  display: block;
  width: 20rem;
  height: 2.2rem;
  margin: 0 auto;
  margin-top: 1rem;
  line-height: 2.2rem;
  text-align: center;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  border-radius: 1.25rem;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
  color: #fff;
}
.StaffCode_Mask_codeimg {
  width: 8.5rem;
  height: 8.5rem;
  position: absolute;
  left: 27%;
  top: 15%;
}
.StaffCode_Mask_codeimg img {
  width: 100%;
}
.StaffCode_Mask_notice {
  width: 20rem;
  height: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  color: #fff;
  /* background-color: orangered; */
  position: absolute;
  left: 7%;
  top: 5%;
}
.StaffCode_Mask_notice p {
  width: 80%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.1rem;
  font-size: 1.1rem;
}
.StaffCode_Mask i {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-weight: bold;
}
</style>