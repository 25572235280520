import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// 引入饿了么组件
import ElementUI from 'element-ui';
// // 引用字体图标库
import '../public/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
//引入全局过滤器
import '@/util/filter' 
//引入全局http
import '@/util/http' 

Vue.config.productionTip = false
Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/apis'

// 调用饿了么组件
Vue.use(ElementUI);

// router.beforeEach((to,from,next)=>{
//   var token  = JSON.parse(localStorage.getItem("user"));
//   if(token == ''|| token == 'undefined' || token == null){
//     if(to.path !== '/login'){
//       next ({ path:'/login'})
//     }else{
//       next()
//     }
//   }
//   else{
//     next()
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
