<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>二维码设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/QRCodeSetting">二维码设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="/#/CollectionCode">收款码</a></el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-show="Oneshow == true"><a href="javascript:void(0)">收款码详情</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="collectionCode_circle">
            <!-- <van-circle
              v-model="currentRate"
              :rate="54"
              :speed="100"
              :text="text"
              layer-color="#fea09c"
              color="#ffd64d"
              :stroke-width="80"
            ></van-circle> -->
            <div style="width:5rem;height:5rem;border-radius:50%;border:0.4rem solid #fff; display: flex;
            align-items: center;
            justify-content: center;
             font-size: 1rem;
            font-weight: bold;
            color: #fff;
            opacity:0.85
            ">
              66
            </div>
            <p>当前店铺收款编码（张）</p>
          </div>
          <div class="collectionCode_content" @click="Oneshow = true">
            <div class="collectionCode_content_left">
              <div class="collectionCode_content_img">
                <img src="../../../public/images/index/img36.png" alt="" />
              </div>
              <div class="collectionCode_content_info">
                <p>收款码</p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="collectionCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="collectionCode_content">
            <div class="collectionCode_content_left">
              <div class="collectionCode_content_img">
                <img src="../../../public/images/index/img36.png" alt="" />
              </div>
              <div class="collectionCode_content_info">
                <p>收款码</p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="collectionCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="collectionCode_content">
            <div class="collectionCode_content_left">
              <div class="collectionCode_content_img">
                <img src="../../../public/images/index/img36.png" alt="" />
              </div>
              <div class="collectionCode_content_info">
                <p>收款码</p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="collectionCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
        </div>
        <!-- 收款码 -->
        <div class="right" v-show="Oneshow">
          <div class="StoreQRCode_main">
            <div class="StoreQRCode_content" @click="show = true">
              <p>图片:</p>
              <div class="StoreQRCode_content_img">
                <img src="../../../public/images/index/img40.png" alt="" />
              </div>
              <i class="iconfont icon-youjiantou"></i>
            </div>
            <div class="StoreQRCode_content">
              <p>类型:</p>
              <span>店铺二维码</span>
            </div>
            <div class="StoreQRCode_content">
              <p>编号:</p>
              <span>8454001000006041963</span>
            </div>
            <div class="StoreQRCode_content">
              <p>生成时间:</p>
              <span>2021-03-17</span>
            </div>
            <div class="StoreQRCode_content" style="border:none">
              <p>所属店铺:</p>
              <span>车管家大剧院店</span>
            </div>
          </div>
        </div>
        <van-overlay :show="show" @click="show = false">
          <div class="StoreQRCode_Mask">
            <img src="../../../public/images/index/img41.png" alt="" />
            <div class="StoreQRCode_Mask_codeimg">
              <img src="../../../public/images/index/img42.png" alt="" />
            </div>
            <div class="StoreQRCode_Mask_notice">
              <p>商家名称: <span>车管家南坊店</span></p>
              <p>收款编码: <span>96584521455243827544</span></p>
            </div>
            <i class="iconfont icon-guanbi"></i>
          </div>

          <a href="javascript:void(0)" class="StoreQRCode_mask_btn">
            保存收款码
          </a>
        </van-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup, Dialog, Toast, Circle ,Overlay} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Circle);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>二维码设置=>收款码
      Oneshow: false,
      // 首页=>二维码设置=>员工码
      Threeshow: false,
      //   收款码数量
      currentRate: 0,
      //   遮罩层图片
      show: false,
    };
  },
  mounted() {},
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   跳转收款码子页面
    handlePageCollectionCode() {
      this.$router.push({
        path: "/CollectionCode",
      });
    },
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #807373;
    margin-right: 5rem;
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  // 左边
  .collectionCode_circle {
    width: 100%;
    /* height: 8.8rem; */
    background-color: #fc4a30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 0;
    margin-bottom: 0.77rem;
  }

  .collectionCode_circle p {
    font-size: 0.85rem;
    color: #fff;
    margin-top: 0.77rem;
  }
  .van-circle__text {
    color: #fff !important;
    font-size: 1.2rem !important;
  }
  .collectionCode_content {
    width: 94%;
    height: 6rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    margin-top: 0.3rem;
  }
  .collectionCode_content_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .collectionCode_content_img {
    width: 3.125rem;
    height: 4.375rem;
    margin-left: 0.35rem;
  }
  .collectionCode_content_img img {
    width: 100%;
  }
  .collectionCode_content_info {
    margin-left: 0.5rem;
    font-size: 0.85rem;
  }
  .collectionCode_content_info p {
    margin-bottom: 0.3rem;
  }
  .collectionCode_content_info span {
    font-size: 0.8rem;
    color: #bfbdbd;
    width: 16.5rem;
    display: block;
    /* background-color: pink; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .collectionCode_content_info b {
    font-weight: normal;
  }
  .collectionCode_content_right {
    color: #a9a9a9;
    margin-right: 0.77rem;
  }
  .collectionCode_btn {
    display: block;
    width: 80%;
    height: 2.1rem;
    background: linear-gradient(to right, #fc4a30, #fa2051);
    margin: 0 auto;
    margin-top: 4.7rem;
    line-height: 2.1rem;
    text-align: center;
    border-radius: 1.25rem;
    color: #fff;
    font-size: 0.9rem;
    letter-spacing: 0.08rem;
  }
}

// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}

.StoreQRCode_main {
  width: 95%;
  margin: 0 auto;
  margin-top: 3.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.77rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.04);
}
.StoreQRCode_content {
  width: 95%;
  display: flex;
  height: 3.8rem;
  background-color: #fff;
  margin: 0 auto;
  font-size: 0.9rem;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.StoreQRCode_content_img {
  width: 2.75rem;
  height: 3.5rem;
}
.StoreQRCode_content p {
  width: 5.5rem;
  letter-spacing: 0.08rem;
}
.StoreQRCode_content_img img {
  width: 100%;
}
.StoreQRCode_content i {
  position: absolute;
  right: 1rem;
  font-size: 1.1rem;
  color: #c7c7c7;
  font-weight: bold;
}
.StoreQRCode_content span {
  width: 70%;
  height: 100%;
  display: block;
  line-height: 3.8rem;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StoreQRCode_Mask {
  width: 18rem;
  height: 23.375rem;
  margin: 0 auto;
  margin-top: 8.8rem;
  position: relative;
}
.StoreQRCode_Mask img {
  width: 100%;
}
.StoreQRCode_mask_btn {
  display: block;
  width: 20rem;
  height: 2.2rem;
  margin: 0 auto;
  margin-top: 4rem;
  line-height: 2.2rem;
  text-align: center;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  border-radius: 1.25rem;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
  color: #fff;
}
.StoreQRCode_Mask_codeimg {
  width: 8.5rem;
  height: 8.5rem;
  position: absolute;
  left: 27%;
  top: 31%;
}
.StoreQRCode_Mask_codeimg img {
  width: 100%;
}
.StoreQRCode_Mask_notice {
  width: 20rem;
  height: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  color: #fff;
  /* background-color: orangered; */
  position: absolute;
  left: -5%;
  top: 78%;
  transform: scale(0.9);
  text-align: center;
}
.StoreQRCode_Mask_notice p {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StoreQRCode_Mask i {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-weight: bold;
}
</style>