<template>
  <div class="loginAll">
    <div class="logo">
      <img src="../../../public/images/index/img32.png" alt="logo" />
    </div>
    <div class="loginMain">
      <h3>欢迎登录</h3>
      <div class="main">
        <div class="content">
          <input type="text" placeholder="请输入账号" v-model="username" />
          <img src="../../../public/images/index/username.png" alt="" />
        </div>
        <div class="content">
          <input type="password" placeholder="请输入密码" v-model="password" />
          <img src="../../../public/images/index/passwords.png" alt="" />
        </div>
      </div>
      <div class="select">
        <van-radio-group
          v-model="radio"
          checked-color="#ea4b36"
          icon-size="1.2rem"
          direction="horizontal"
          @change="handleRadio"
        >
          <van-radio name="1">商家登录</van-radio>
          <van-radio name="2">连锁店登录</van-radio>
        </van-radio-group>
      </div>
      <a href="javascript:void(0)" @click="handleLogin">立即登录</a>
      <p>注册即代表您已阅读并同意新码客服务条款</p>
    </div>
  </div>
</template>

<script>
import http from "@/util/http";
import Vue from "vue";
import { Toast, RadioGroup, Radio } from "vant";

Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
export default {
  data() {
    return {
      username: "",
      password: "",
      radio: "1",
    };
  },
  mounted() {},
  methods: {
    handleRadio(e) {
      console.log(e);
    },
    handleLogin() {
      if (this.username == "") {
        Toast("请输入登录账号");
        return;
      } else if (this.password == "") {
        Toast("请输入密码");
        return;
      }
      http
        .request({
          url: "/shop/login",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            username: this.username,
            password: this.password,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 1) {
            Toast(res.data.msg);
            this.$router.push('/index');
            localStorage.setItem("Authorization", res.data.data.token);
          } else {
            console.log("登陆失败")
            Toast(res.data.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loginAll {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../../public/images/index/img31.png");
  background-size: 100% 100%;
  background-repeat: repeat;
}
.logo {
  width: 12rem;
  height: 4.375rem;
  position: absolute;
  top: 5%;
  left: 5%;
}
.logo img {
  width: 100%;
}

.loginMain {
  width: 21%;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  background: #fff;
  position: absolute;
  right: 12%;
  top: 12%;
  border-radius: 0.5rem;
  padding-top: 0.35rem;
  padding-bottom: 0.77rem;
}
.loginMain h3 {
  width: 90%;
  margin: 0 auto;
  font-size: 1.15rem;
  color: #000;
  padding-top: 1rem;
}
.loginMain .main {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.loginMain .main .content {
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 0.77rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
}
.loginMain .main .content input {
  width: 86%;
  height: 2.5rem;
  border: none;
  font-size: 0.95rem;
  // text-indent: 3.3rem;
}
.loginMain .main .content img:nth-child(1) {
  width: 1.25rem;
  height: 1.375rem;
  position: absolute;
  bottom: 1rem;
  left: 5rem;
}
.loginMain .main .content img:nth-child(2) {
  width: 1.0625rem;
  height: 1.1875rem;
  position: absolute;
  bottom: 0.6rem;
  left: 1rem;
}
.loginMain .select {
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-top: 1.5rem;
  font-size: 0.95rem;

  .van-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
.loginMain a {
  width: 47%;
  margin: 0 auto;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.95rem;
  background: linear-gradient(to right, #e4394c, #ea852a);
  border-radius: 1.25rem;
  margin-top: 2rem;
  letter-spacing: 0.1rem;
}
.loginMain p {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
  font-size: 0.8rem;
  margin-top: 1.85rem;
  letter-spacing: 0.08rem;
  padding-bottom: 1rem;
}
</style>