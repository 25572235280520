<template>
  <div class="VipDetailAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipDetail">
      <UserTitle>
        <template v-slot:title>会员管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/VipManage' }"
            >会员管理</el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><a href="javascript:void(0)">会员详情</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipDetailMain">
        <div class="VipDetailUser">
          <div class="user">
            <div class="avatar">
              <img :src="userinfo.avatar" alt="头像" />
            </div>
            <div class="name">
              <span>{{ userinfo.username }}</span>
            </div>
            <div class="joinTime">
              {{ userinfo.reg_time }}通过线下支付加入会员
            </div>
            <div class="portrait">
              <span>顾客画像</span>
              <a href="javascript:void(0)">
                <!-- <i class="iconfont icon-bianji"></i> -->
                <img src="../../../public/images/index/img21.png" alt="" />
              </a>
            </div>
            <div class="bonus">
              <span>分红状态设置</span>
              <div class="switch">
                <van-switch
                  v-model="checked"
                  size="1.1rem"
                  active-color="#ee0a24"
                  inactive-color="#dcdee0"
                />
              </div>
            </div>
            <div class="birthday" v-if="userinfo.birthday != ''">
              <div class="pic">
                <img src="../../../public/images/index/img22.png" alt="" />
              </div>
              <span>{{ userinfo.birthday }}</span>
            </div>
            <div class="telphone" v-if="userinfo.mobile != ''">
              <div class="left">
                <div class="pic">
                  <img src="../../../public/images/index/img23.png" alt="" />
                </div>
                <span>{{ userinfo.mobile }}</span>
              </div>
              <div class="right">
                <span>展开</span>
                <i class="iconfont icon-jiantou"></i>
              </div>
            </div>
            <div class="Viptag">
              <h4>会员标签</h4>
              <div class="tag">
                <div class="top">
                  <span>新客</span>
                  <span>高消费</span>
                  <span>常充值</span>
                  <span>挑剔服务</span>
                </div>
                <div class="bottom">
                  <span>编辑</span>
                  <i class="iconfont icon-youjiantou"></i>
                </div>
              </div>
            </div>
            <div class="consume">
              <h4>消费信息</h4>
              <h6>仅展示最近三个月的消费数据</h6>
              <div class="consumeMoney">
                <div class="content">
                  <span>{{ userinfo.price }}</span>
                  <p>会员消费金额(元)</p>
                </div>
                <div class="content">
                  <span>{{ userinfo.bonus_money_total }}</span>
                  <p>红包总金额(元)</p>
                </div>
                <div class="content">
                  <span style="color: red">{{ userinfo.bonus_money }}</span>
                  <p>剩余红包金额(元)</p>
                </div>
              </div>
              <p
                style="font-size: 0.85rem; color: #999999; margin-top: 0.77rem"
              >
                最后消费 {{ userinfo.reg_time }}
              </p>
            </div>
            <div class="way">
              <span>开单</span>
              <span>开卡</span>
              <span>预约</span>
              <span>发卷</span>
            </div>
          </div>
        </div>
        <div class="VipDetailMoney">
          <div class="Recharge">
            <div class="Title">
              <a href="javascript:void(0)" class="RechargeActive">
                <span>会员充值总额(元)</span>
                <p>{{ userinfo.balance_total }}</p>
              </a>
              <a href="javascript:void(0)">
                <span>会员卡余额(元)</span>
                <p style="color: #2ac845">{{ userinfo.balance }}</p>
              </a>
            </div>
            <div class="nav">
              <span
                style="
                  width: 27.5%;
                  display: flex;
                  justify-content: flex-start;
                  margin-left: 1.75rem;
                "
                >充值时间</span
              >
              <span style="width: 15%">充值金额 </span>
              <span style="width: 15%">赠送金额</span>
              <span style="width: 27.5%">总金额</span>
              <span style="width: 15%">支付状态</span>
            </div>
            <div class="RechargeMain">
              <div class="content" v-for="data in Onedatalist" :key="data.id">
                <span
                  style="
                    width: 27.5%;
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 1.75rem;
                  "
                  >{{ data.create_time }}</span
                >
                <span style="width: 15%">{{ data.money }}</span>
                <span style="width: 15%">{{ data.give_money }}</span>
                <span style="width: 27.5%">{{ data.total_money }}</span>
                <a href="javascript:void(0)" style="width: 15%">
                  <span v-if="data.payment == 1" style="color: #21d15e"
                    >已支付</span
                  >
                  <span v-if="data.payment == 0" style="color: red"
                    >未支付</span
                  >
                </a>
              </div>
            </div>
            <div class="RechargePagination">
              <van-pagination
                v-model="currentPageOne"
                :total-items="Onetotal"
                mode="simple"
                @change="handleChangePageOne"
              />
            </div>
          </div>

          <div class="Record">
            <div class="Title">
              <a href="javascript:void(0)" class="RecordActive">
                <span>消费记录 <b>{{Twototal}}</b></span>
                <!-- <p>0.00</p> -->
              </a>
            </div>
            <div class="nav">
              <span
                style="
                  width: 22%;
                  display: flex;
                  justify-content: flex-start;
                  margin-left: 1.75rem;
                "
                >消费时间</span
              >
              <span style="width: 15.6%">订单金额</span>
              <span style="width: 15.6%">实付金额</span>
              <span style="width: 15.6%">会员卡</span>
              <span style="width: 15.6%">红包</span>
              <span style="width: 15.6%">订单状态</span>
            </div>
            <div class="RecordMain">
              <div class="content" v-for="data in Twodatalist" :key="data.id">
                <span
                  style="
                    width: 22%;
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 1.75rem;
                  "
                  >{{data.create_time}}</span
                >
                <span style="width: 15.6%">{{data.order_money}}</span>
                <span style="width: 15.6%">{{data.money}}</span>
                <span style="width: 15.6%">{{data.card_money}}</span>
                <span style="width: 15.6%">{{data.bonus}}</span>
                <span style="width: 15.6%">
                  <span v-if="data.pay_type == 1" style="color: #21d15e"
                    >已支付</span
                  >
                  <span v-if="data.pay_type == 0" style="color: red"
                    >未支付</span
                  >
                </span>
              </div>
            </div>
            <div class="RecordPagination">
              <van-pagination
                v-model="currentPageTwo"
                :total-items="Twototal"
                mode="simple"
                @change="handleChangePageTwo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/util/http";
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Switch, Pagination } from "vant";

Vue.use(Switch);
Vue.use(Pagination);

export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 滑块开关
      checked: true,
      //   分页1
      currentPageOne: 1,
      // 分页2
      currentPageTwo: 1,
      id: "",
      userinfo: "",
      // 会员充值总额数据
      Onedatalist: [],
      Onepage: 1,
      OnetotalPage: "",
      Oneper_page: "",
      Onetotal: 0,
      // 消费记录
       Twodatalist: [],
      Twopage: 1,
      TwototalPage: "",
      Twoper_page: "",
      Twototal: 0,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    let id = this.id;
    console.log("id", this.id);

    http
      .request({
        url: `shop/indexUserInfo?id=${id}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.userinfo = res.data.data;
        console.log(77, this.userinfo);
        if (res.data.data.fen == 0) {
          this.checked = false;
        } else {
          this.checked = true;
        }
      });

    // 会员充值总额
    let { Onepage, Onedatalist } = this;
    http
      .request({
        url: "shop/indexRechargeRecord",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          user_id: this.id,
          page: this.currentPageOne
        },
      })
      .then((res) => {
        // if (page > 1 && datalist.length > 0) {
        // datalist = datalist.concat(res.data.data.data);
        // } else {
        Onedatalist = [];
        Onedatalist = res.data.data.data;
        // }
        this.Onedatalist = Onedatalist;
        this.OnetotalPage = res.data.data.last_page;
        this.Oneper_page = res.data.data.per_page;
        this.Onetotal = res.data.data.total;
        console.log(778, this.Onedatalist);
      });

      // 消费记录
      let { Twopage, Twodatalist } = this;
    http
      .request({
        url: "shop/indexConsumeList",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          user_id: this.id,
          page: this.currentPageTwo
        },
      })
      .then((res) => {
        // if (page > 1 && datalist.length > 0) {
        // datalist = datalist.concat(res.data.data.data);
        // } else {
        Twodatalist = [];
        Twodatalist = res.data.data.data;
        // }
        this.Twodatalist = Twodatalist;
        this.TwototalPage = res.data.data.last_page;
        this.Twoper_page = res.data.data.per_page;
        this.Twototal = res.data.data.total;
        console.log(778888, this.Twodatalist);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    handleChangePageOne() {
      console.log(this.currentPageOne);
      let { Onepage, Onedatalist } = this;
      http
        .request({
          url: "shop/indexRechargeRecord",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            user_id: this.id,
            page:this.currentPageOne
          },
        })
        .then((res) => {
          // if (page > 1 && datalist.length > 0) {
          // datalist = datalist.concat(res.data.data.data);
          // } else {
          Onedatalist = [];
          Onedatalist = res.data.data.data;
          // }
          this.Onedatalist = Onedatalist;
          this.OnetotalPage = res.data.data.last_page;
          this.Oneper_page = res.data.data.per_page;
          this.Onetotal = res.data.data.total;
          console.log(778, this.Onedatalist);
        });
    },
    // 消费记录分页
    handleChangePageTwo(){
       console.log(this.currentPageTwo);
      let { Onepage, Onedatalist } = this;
      http
        .request({
          url: "shop/indexConsumeList",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            user_id: this.id,
            page:this.currentPageTwo
          },
        })
        .then((res) => {
          // if (page > 1 && datalist.length > 0) {
          // datalist = datalist.concat(res.data.data.data);
          // } else {
        Twodatalist = [];
        Twodatalist = res.data.data.data;
        // }
        this.Twodatalist = Twodatalist;
        this.TwototalPage = res.data.data.last_page;
        this.Twoper_page = res.data.data.per_page;
        this.Twototal = res.data.data.total;
        console.log(778888, this.Twodatalist);
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.VipDetailAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipDetail {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipDetailMain {
  width: 98%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-radius: 0.5rem;
  background: #f7f8fa;

  .VipDetailUser {
    width: 21%;
    height: 100%;
    background: #fff;
    border-radius: 0.5rem;

    .user {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      .avatar {
        width: 4.375rem;
        height: 4.375rem;
        border-radius: 50%;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .name {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        margin: 0.7rem 0;
        letter-spacing: 0.03rem;
      }
      .joinTime {
        font-size: 0.75rem;
        letter-spacing: 0.03rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #a5a8ad;
        margin-bottom: 0.7rem;
      }
      .portrait {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        span {
          padding: 0.38rem 0.9rem;
          border-radius: 0.4rem;
          color: #fff;
          background: #ff4778;
          font-size: 0.75rem;
          letter-spacing: 0.02rem;
          margin-right: 0.5rem;
        }
        a {
          width: 2.1125rem;
          height: 1.9875rem;
          img {
            width: 100%;
          }
        }
      }
      .bonus {
        width: 52%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.88rem;
        span {
          font-size: 0.8rem;
          color: #999999;
          letter-spacing: 0.04rem;
        }
        .switch {
          margin-top: 0.2rem;
        }
      }
      .birthday {
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-size: 0.85rem;
        .pic {
          width: 0.875rem;
          height: 0.875rem;
          margin-right: 0.5rem;
          img {
            width: 100%;
          }
        }
      }
      .telphone {
        width: 85%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.85rem;
        margin-top: 0.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #e7e7e7;
        .left {
          display: flex;
          align-items: center;
          .pic {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
            img {
              width: 100%;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          color: #999999;
          i {
            font-size: 0.7rem;
            color: #999999;
            margin-left: 0.2rem;
          }
        }
      }
      .Viptag {
        width: 85%;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        h4 {
          font-size: 1rem;
          letter-spacing: 0.02rem;
        }
        .tag {
          display: flex;
          flex-direction: column;
          padding-bottom: 1rem;
          border-bottom: 1px dashed #e7e7e7;
          .top {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 0.88rem;
            span {
              color: #999999;
              padding: 0.12rem 0.8rem;
              background: #f7f8fa;
              border: 1px solid #d8d8d8;
              border-radius: 0.35rem;
              margin-right: 0.4rem;
              font-size: 0.75rem;
              margin-bottom: 0.4rem;
            }
          }
          .bottom {
            display: flex;
            align-items: center;
            color: #999999;
            font-size: 0.75rem;
            margin-top: 0.5rem;
            i {
              font-size: 0.7rem;
              color: #999999;
              margin-left: 0.2rem;
            }
          }
        }
      }
      .consume {
        width: 85%;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #e7e7e7;
        h4 {
          font-size: 1rem;
          letter-spacing: 0.02rem;
          margin-top: 1rem;
        }
        h6 {
          font-size: 0.85rem;
          letter-spacing: 0.02rem;
          font-weight: normal;
          color: #999999;
          margin-top: 0.77rem;
        }
        .consumeMoney {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background: #f7f8fa;
          padding: 1rem 0;
          margin-top: 0.77rem;
          border-radius: 0.25rem;
          .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              font-weight: bold;
              font-size: 1.2rem;
              color: #000;
            }
            p {
              font-size: 0.75rem;
              color: #999999;
              margin-top: 0.3rem;
            }
          }
        }
      }
      .way {
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
        padding-bottom: 2rem;
        span {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          background: #fff;
          color: #999999;
          padding: 0.3rem 0.88rem;
          border-radius: 0.25rem;
          //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          color: #000;
          font-size: 0.95rem;
        }
      }
    }
  }
  .VipDetailMoney {
    width: 77%;
    height: 100%;
    // background: skyblue;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    padding-bottom: 5rem;
    .Recharge {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 0.5rem;
      margin-bottom: 1.4rem;

      .Title {
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        a {
          width: 10rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.88rem 0;
          box-sizing: border-box;
          span {
            font-size: 0.875rem;
            color: #565656;
          }
          p {
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 0.5rem;
          }
        }
        .RechargeActive {
          border-top: 2px solid red;
          box-sizing: border-box;
          span {
            margin-top: -0.125rem;
          }
        }
        a:nth-child(1) {
          border-right: 1px dashed #e7e7e7;
        }
      }
      .nav {
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0.77rem;
        border-top: 1px solid #f7f7f7;
        border-bottom: 1px solid #f7f7f7;
        margin-top: 1rem;
        span {
          color: #999999;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .RechargeMain {
        width: 98%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .content {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.88rem 0;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #3a3836;
          }
          a {
            color: #ff5f8a;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
          }
        }
      }
      .RechargePagination {
        width: 98%;
        margin: 0 auto;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        border-top: 1px solid #f7f7f7;
        .van-pagination {
          width: 100%;
        }
      }
    }
    // 消费记录
    .Record {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 0.5rem;
      margin-bottom: 1.4rem;

      .Title {
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0.4rem 0;
        a {
          width: 10rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem 0;
          box-sizing: border-box;
          span {
            font-size: 1rem;
            color: #565656;
          }
          p {
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 0.5rem;
          }
        }
        .RecordActive {
          border-top: 2px solid red;
          box-sizing: border-box;
          color: red !important;
          span {
            margin-top: 0.125rem;
            color: red !important;
          }
        }
        // a:nth-child(1) {
        //   border-right: 1px dashed #e7e7e7;
        // }
      }
      .nav {
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0.77rem;
        border-top: 1px solid #f7f7f7;
        border-bottom: 1px solid #f7f7f7;
        margin-top: 1rem;
        span {
          color: #999999;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .RecordMain {
        width: 98%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .content {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.88rem 0;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #3a3836;
          }
          a {
            color: #ff5f8a;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
          }
        }
      }
      .RecordPagination {
        width: 98%;
        margin: 0 auto;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        border-top: 1px solid #f7f7f7;
        .van-pagination {
          width: 100%;
        }
      }
    }
  }
}
</style>