<template>
  <div class="ShopSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="ShopSetting">
      <UserTitle>
        <template v-slot:title>门店设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="javascript:void(0)">门店设置</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="ShopSettingMain">
        <!-- <div class="shopLogo">
          <p>店铺LOGO或门头照</p>
          <van-uploader
            v-model="logoPic"
            :max-count="1"
            :preview-full-image="false"
          />
        </div> -->
        <div class="name">
          <p>店铺名称</p>
          <input type="text" v-model="name" placeholder="请填写店铺名称" />
        </div>
        <div class="phone">
          <p>联系电话</p>
          <input type="number" v-model="mobile" placeholder="请填写联系电话" />
        </div>
        <div class="linkName">
          <p>联系人</p>
          <input type="text" v-model="linkName" placeholder="请填写联系人" />
        </div>
        <!-- <div class="linkArea">
          <p>联系地址</p>
          <input
            type="text"
            v-model="linkArea"
            placeholder="请选择联系地址"
            @click="showArea = true"
            readonly="readonly"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
        </div> -->
        <div class="detailArea">
          <p>详细地址</p>
          <textarea
            cols="30"
            rows="10"
            v-model="detailArea"
            placeholder="请填写详细地址"
          ></textarea>
        </div>
        <div class="time">
          <p>营业时间</p>
          <!-- <div @click="startTimeShow = true">{{ startTime }}</div>
          <span style="margin: 0 2rem">至</span>
          <div @click="endTimeShow = true">{{ endTime }}</div> -->
          <input type="text" placeholder="请输入营业时间" v-model="time" />
        </div>
        <van-popup v-model="startTimeShow" position="bottom">
          <van-datetime-picker
            v-model="startTime"
            type="time"
            title="选择时间"
            @confirm="handleStartTime"
          />
        </van-popup>
        <van-popup v-model="endTimeShow" position="bottom">
          <van-datetime-picker
            v-model="endTime"
            type="time"
            title="选择时间"
            @confirm="handleEndTime"
          />
        </van-popup>
        <div class="orderOnOff">
          <p>预约接单</p>
          <div class="main">
            <nav>
              <van-switch v-model="checked" size="24" />
              <!-- <span v-if="checked == false">已关闭</span>
              <span v-if="checked == true">已开启</span> -->
              <b>开启后 用户成功下单后系统将自动接单并发送打印指令</b>
            </nav>
            <div class="content"></div>
          </div>
        </div>
        <div class="ShopNotice">
          <p>店铺公告</p>
          <textarea
            cols="30"
            rows="10"
            v-model="ShopNotice"
            placeholder="请填写公告"
          ></textarea>
        </div>
        <!-- <van-field name="uploader" label="店铺LOGO">
          <template #input>
            <van-uploader
              v-model="logoPic"
              :max-count="1"
              :preview-full-image="false"
            />
          </template>
        </van-field>
        <van-field
          v-model="name"
          name="店铺名称"
          label="店铺名称"
          placeholder="请填写店铺名称"
          :rules="[{ required: true, message: '请填写店铺名称' }]"
        />
        <van-field
          v-model="mobile"
          type="number"
          name="联系电话"
          label="联系电话"
          placeholder="请填写联系电话"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <van-field
          v-model="linkName"
          name="联系人"
          label="联系人"
          placeholder="请填写联系人"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          readonly
          clickable
          name="area"
          :value="area"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="detailArea"
          name="详细地址"
          label="详细地址"
          placeholder="请填写详细地址"
          :rules="[{ required: true, message: '请填写详细地址' }]"
        />


        <van-field
          readonly
          clickable
          name="time"
          :value="time"
          label="时间选择"
          placeholder="点击选择营业时间"
          @click="showTime = true"
        />
        <van-popup v-model="showTime" position="bottom">
          <div
            style="
              width: 100%;
              height: 7rem;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <el-time-picker
              is-range
              v-model="value1"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              @change="handleTime"
            >
            </el-time-picker>
          </div>
        </van-popup>

        <div>
          <van-field
            v-model="mobile"
            type="number"
            name="预约接单"
            label="预约接单"
            :rules="[{ required: true, message: '请填写联系电话' }]"
          />
        </div>
        -->
      </div>
      <a href="javascript:void(0)" class="SaveBtn" @click="handleSave"
        >保存设置</a
      >
    </div>
  </div>
</template>
<script>
import http from "@/util/http";
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Toast } from "vant";
import {
  Form,
  Field,
  Uploader,
  Area,
  Popup,
  DatetimePicker,
  SwitchCell,
} from "vant";
Vue.use(Toast);
import { areaList } from "@vant/area-data";
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Area);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(SwitchCell);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 店铺Logo
      logoPic: [],
      // 店铺名称
      name: "",
      // 联系电话
      mobile: "",
      //   联系人
      linkName: "",
      //   联系地址
      linkArea: "",
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      // 详细地址
      detailArea: "",
      // value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      // showTime: false,
      // 开始时间
      startTimeShow: false,
      startTime: "开始时间",
      //结束时间
      endTimeShow: false,
      endTime: "结束时间",
      // 预约接单开关
      checked: false,
      // 店铺公告
      ShopNotice: "",
      // 营业时间
      time: "",
      // 店铺数据
      datalist: "",
    };
  },
  mounted() {
    http
      .request({
        url: "shop/indexStoreSet",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.datalist = res.data.data;
        console.log(1, this.datalist);
        this.name = this.datalist.name;
        this.mobile = this.datalist.tel;
        this.linkName = this.datalist.username;
        this.detailArea = this.datalist.ads;
        this.time = this.datalist.business_hours;
        if (this.datalist.system_state == 1) {
          this.checked = true;
        } else {
          this.checked = false;
        }
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   确认联系地址选择
    onConfirm(values) {
      this.linkArea = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      this.showArea = false;
      console.log(this.linkArea);
    },
    // 开始时间
    handleStartTime(val) {
      console.log(val);
      this.startTimeShow = false;
    },
    // 结束时间
    handleEndTime(val) {
      console.log(val);
      this.endTimeShow = false;
    },
    // 保存设置
    handleSave() {
      http
        .request({
          url: "shop/indexStoreSet",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            name: this.name,
            tel: this.mobile,
            username: this.linkName,
            ads: this.detailArea,
            business_hours: this.time,
            system_state: this.checked,
            ShopNotice: this.ShopNotice,
          },
        })
        .then((res) => {
          console.log("保存", res);
          if (res.data.code == 1) {
            Toast(res.data.msg);
            this.$router.push("/index");
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ShopSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.ShopSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.ShopSettingMain {
  width: 92%;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  .shopLogo {
    width: 96%;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-family: PingFang SC;
    // font-weight: bold;
    color: #525252;
    padding: 0.88rem 0;
    border-bottom: 1px solid #f7f7f7;
    p {
      width: 9rem;
    }
    a {
      font-size: 0.75rem;
      font-family: PingFang SC;
      color: #7d7c8b;
      padding: 0.24rem 0.8rem;
      border: 1px solid #acacac;
    }
  }
  .name,
  .phone,
  .linkName,
  .linkArea,
  .time {
    width: 96%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-family: PingFang SC;
    color: #525252;
    padding: 0.88rem 0;
    border-bottom: 1px solid #f7f7f7;
    // background: #fff;
    p {
      width: 9rem;
      // background: skyblue;
    }
    input {
      // width: 20rem;
      flex: 1;
      // margin-left: 5rem;
      height: 1.2rem;
      border: none;
    }
  }
  .detailArea,
  .ShopNotice {
    width: 96%;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    font-size: 0.8rem;
    font-family: PingFang SC;
    color: #525252;
    padding: 0.88rem 0;
    border-bottom: 1px solid #f7f7f7;
    // background: #fff;
    p {
      width: 9rem;
      // background: skyblue;
    }
    textarea {
      // width: 20rem;
      flex: 1;
      // margin-left: 5rem;
      height: 3.4rem;
      border: none;
    }
  }
  .orderOnOff {
    width: 96%;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    font-size: 0.8rem;
    font-family: PingFang SC;
    color: #525252;
    padding: 0.88rem 0;
    border-bottom: 1px solid #f7f7f7;
    p {
      width: 9rem;
    }
    .main {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      nav {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        span {
          font-weight: bold;
          margin-bottom: 0.4rem;
          color: #525252;
        }
        b {
          font-size: 0.75rem;
          color: #525252;
          font-weight: normal;
          margin-top: 0.55rem;
        }
      }
    }
  }
}
.SaveBtn {
  margin: 0 auto;
  margin-top: 1.65rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 3rem;
  background: linear-gradient(99deg, #fc4a30 0%, #fa2052 100%);
  border-radius: 0.3rem;
  font-size: 1rem;
  font-family: PingFang SC;
  // font-weight: bold;
  color: #ffffff;
  letter-spacing: 0.03rem;
}
</style>