import Vue from 'vue'
import Vuex from 'vuex'
import http from "@/util/http";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
  },
  mutations: {
    // 创建token
    changeLogin (state, user) {
      state.Authorization = user.Authorization;
      localStorage.setItem('Authorization', user.Authorization);
    },
  },
  actions: {
  },
  modules: {
  }
})
