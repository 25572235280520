<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>二维码设置</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/QRCodeSetting">二维码设置</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="javascript:void(0)">收款码</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="javascript:void(0)">员工码</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Threeshow == true"
            ><a href="javascript:void(0)">云店二维码</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="main">
            <div class="ercode_content">
              <ul>
                <li
                  class="collection_code"
                  @click="
                    Oneshow = true;
                    Twoshow = false;
                    Threeshow = false;
                  "
                >
                  <div class="code_left">
                    <div class="code_img">
                      <img
                        src="../../../public/images/index/img33.png"
                        alt=""
                      />
                    </div>
                    <p>收款码</p>
                  </div>
                  <i class="iconfont icon-youjiantou"></i>
                </li>
                <li
                  class="staff_qrcode"
                  @click="
                    Oneshow = false;
                    Twoshow = true;
                    Threeshow = false;
                  "
                >
                  <div class="code_left">
                    <div class="code_img">
                      <img
                        src="../../../public/images/index/img34.png"
                        alt=""
                      />
                    </div>
                    <p>员工码</p>
                  </div>
                  <i class="iconfont icon-youjiantou"></i>
                </li>
                <li
                  class="cloudstore_qrcode"
                  @click="
                    Oneshow = false;
                    Twoshow = false;
                    Threeshow = true;
                  "
                >
                  <div class="code_left">
                    <div class="code_img">
                      <img
                        src="../../../public/images/index/img35.png"
                        alt=""
                      />
                    </div>
                    <p>云店二维码</p>
                  </div>
                  <i class="iconfont icon-youjiantou"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 收款码 -->
        <div class="right" v-show="Oneshow">
          <div class="StoreQRCode_main">
            <div class="StoreQRCode_content" @click="show = true">
              <p>图片:</p>
              <div class="StoreQRCode_content_img">
                <img src="../../../public/images/index/img40.png" alt="" />
              </div>
              <i class="iconfont icon-youjiantou"></i>
            </div>
            <div class="StoreQRCode_content">
              <p>类型:</p>
              <span>店铺二维码</span>
            </div>
            <!-- <div class="StoreQRCode_content">
              <p>编号:</p>
              <span>8454001000006041963</span>
            </div> -->
            <!-- <div class="StoreQRCode_content">
              <p>生成时间:</p>
              <span>2021-03-17</span>
            </div> -->
            <div class="StoreQRCode_content" style="border: none">
              <p>所属店铺:</p>
              <span>{{ collectionlist.shop_name }}</span>
            </div>
          </div>
          <van-overlay :show="show" @click="show = false">
            <div class="StoreQRCode_Mask">
              <img src="../../../public/images/index/img41.png" alt="" />
              <div class="StoreQRCode_Mask_codeimg">
                <img :src="collectionlist.img" alt="收款码" />
              </div>
              <div class="StoreQRCode_Mask_notice">
                <p>
                  商家名称: <span>{{ collectionlist.shop_name }}</span>
                </p>
                <!-- <p>收款编码: <span>96584521455243827544</span></p> -->
              </div>
              <i class="iconfont icon-guanbi"></i>
            </div>

            <a href="javascript:void(0)" class="StoreQRCode_mask_btn">
              保存收款码
            </a>
          </van-overlay>
        </div>

        <!-- 员工码 -->
        <div class="right" v-show="Twoshow">
          <div class="StaffQRCode_circle">
            <!-- <van-circle
              v-model="currentRateTwo"
              :rate="currentRateTwo"
              :speed="100"
              :text="textTwo"
              layer-color="#ffe7a6"
              color="#fff"
              :stroke-width="80"
            ></van-circle> -->
            <div
              style="
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                border: 0.4rem solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                opacity: 0.85;
              "
            >
              {{ staffcodelist.code_num }}
            </div>
            <p>员工二维码数量（张）</p>
          </div>
          <div class="main" style="flex: 1; overflow: scroll">
            <div
              class="StaffQRCode_content"
              @click="handlePageStaffCode"
              v-for="data in staffcodelist.list"
              :key="data.id"
            >
              <div class="StaffQRCode_content_left">
                <div class="StaffQRCode_content_img">
                  <img src="../../../public/images/index/img37.png" alt="" />
                </div>
                <div class="StaffQRCode_content_info">
                  <p>
                    员工姓名：<i>{{ data.uname }}</i>
                  </p>
                  <span
                    >收款编号：<b>{{ data.job_number }}</b></span
                  >
                </div>
              </div>
              <div class="StaffQRCode_content_right">
                <i class="iconfont icon-youjiantou"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- 云店二维码 -->
        <div class="right" v-show="Threeshow">
          <div class="CloudStoreCode_circle">
            <!-- <van-circle
              v-model="currentRateThree"
              :rate="rate"
              :speed="100"
              :text="textThree"
              layer-color="#ffe7a6"
              color="#fff"
              :stroke-width="80"
            ></van-circle> -->
            <div
              style="
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                border: 0.4rem solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
                opacity: 0.85;
              "
            >
              66
            </div>
            <p>当前分店二维码（张）</p>
          </div>
          <div class="main" style="flex: 1; overflow: scroll">

          <div
            class="CloudStoreCode_content"
            @click="handlePageYunDianQrCode()"
          >
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img38.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div><div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div><div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div><div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          <div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div><div class="CloudStoreCode_content">
            <div class="CloudStoreCode_content_left">
              <div class="CloudStoreCode_content_img">
                <img src="../../../public/images/index/img39.png" alt="" />
              </div>
              <div class="CloudStoreCode_content_info">
                <p>店铺名称：<i>车管家大剧院店</i></p>
                <span>收款编号：<b>84540010000006041963</b></span>
              </div>
            </div>
            <div class="CloudStoreCode_content_right">
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup, Dialog, Toast, Circle } from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Circle);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>二维码设置=>收款码
      Oneshow: false,
      // 首页=>二维码设置=>员工码
      Twoshow: false,
      // 首页=>二维码设置=>云店二维码
      Threeshow: false,
      //   收款码数量
      currentRateOne: 7,
      // 员工码数量
      currentRateTwo: 77,
      // 云店二维码数量
      currentRateThree: 99,
      // 目标进度
      rate: 9999999,
      // 收款码列表数据
      collectionlist: "",
      //   收款码遮罩层图片
      show: false,
      // 员工码列表数据
      staffcodelist: "",
    };
  },
  mounted() {
    // 收款码数据列表
    http
      .request({
        url: "/shop/indexPayCode",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.collectionlist = res.data.data;
        // console.log(this.collectionlist);
      });
    // 员工码数据列表
    http
      .request({
        url: "/shop/indexStaffCodeList",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.staffcodelist = res.data.data;
        console.log(77, this.staffcodelist);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   跳转收款码子页面
    handlePageCollectionCode() {
      this.$router.push({
        path: "/CollectionCode",
      });
    },
    // 跳转员工码子页面
    handlePageStaffCode() {
      this.$router.push({
        path: "/StaffCode",
      });
    },
    // 跳转云店二维码子页面
    handlePageYunDianQrCode() {
      this.$router.push({
        path: "/YunDianQrCode",
      });
    },
  },
  computed: {
    textOne() {
      return this.currentRateOne.toFixed(0);
    },
    textTwo() {
      return this.currentRateTwo.toFixed(0);
    },
    textThree() {
      return this.currentRateThree.toFixed(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    .main {
      display: flex;
      flex-direction: column;
      .content {
        width: 86%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftContent {
          display: flex;
          align-items: center;
          .img {
            width: 2.5625rem;
            height: 1.8125rem;
            img {
              width: 100%;
            }
          }
          span {
            font-size: 0.875rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}
// 左边
.ercode_content {
  width: 100%;
  background: #fff;
}
.ercode_content ul {
  width: 80%;
  margin: 0 auto;
}

.ercode_content ul li {
  height: 3.625rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  border-bottom: 1px solid #f3f3f3;
}
.ercode_content ul li .code_left {
  display: flex;
  align-items: center;
}
.ercode_content ul li .code_left .code_img {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
}
.ercode_content ul li .code_left .code_img img {
  width: 100%;
}
.ercode_content ul li i {
  font-size: 1.2rem;
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.VipcardSettingMain .right ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.collectionCode_circle {
  width: 100%;
  /* height: 8.8rem; */
  background-color: #fc4a30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
  margin-bottom: 0.77rem;
}

.collectionCode_circle p {
  font-size: 0.85rem;
  color: #fff;
  margin-top: 0.77rem;
}
.van-circle__text {
  color: #fff !important;
  font-size: 1.2rem !important;
}
.collectionCode_content {
  width: 94%;
  height: 6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-top: 0.3rem;
}
.collectionCode_content_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.collectionCode_content_img {
  width: 3.125rem;
  height: 4.375rem;
  margin-left: 0.35rem;
}
.collectionCode_content_img img {
  width: 100%;
}
.collectionCode_content_info {
  margin-left: 0.5rem;
  font-size: 0.85rem;
}
.collectionCode_content_info p {
  margin-bottom: 0.3rem;
}
.collectionCode_content_info span {
  font-size: 0.8rem;
  color: #bfbdbd;
  width: 16.5rem;
  display: block;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collectionCode_content_info b {
  font-weight: normal;
}
.collectionCode_content_right {
  color: #a9a9a9;
  margin-right: 0.77rem;
}
.collectionCode_btn {
  display: block;
  width: 80%;
  height: 2.1rem;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  margin: 0 auto;
  margin-top: 4.7rem;
  line-height: 2.1rem;
  text-align: center;
  border-radius: 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
}

.StaffQRCode_circle {
  width: 100%;
  /* height: 8.8rem; */
  background-color: #ffd24d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
  margin-bottom: 0.77rem;
}

.StaffQRCode_circle p {
  font-size: 0.85rem;
  color: #000;
  margin-top: 0.77rem;
}
.van-circle__text {
  color: #000 !important;
  font-size: 1.2rem !important;
}
.StaffQRCode_content {
  width: 94%;
  height: 6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-top: 0.3rem;
}
.StaffQRCode_content_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.StaffQRCode_content_img {
  width: 3rem;
  height: 3rem;
  margin-left: 0.35rem;
}
.StaffQRCode_content_img img {
  width: 100%;
}
.StaffQRCode_content_info {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
}
.StaffQRCode_content_info p {
  margin-bottom: 0.3rem;
  width: 12rem;
  display: flex;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StaffQRCode_content_info p i {
  width: 100%;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StaffQRCode_content_info span {
  font-size: 0.8rem;
  color: #bfbdbd;
  width: 16.5rem;
  display: block;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StaffQRCode_content_info b {
  font-weight: normal;
}
.StaffQRCode_content_right {
  color: #a9a9a9;
  margin-right: 0.77rem;
}
.StaffQRCode_btn {
  display: block;
  width: 80%;
  height: 2.1rem;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  margin: 0 auto;
  margin-top: 4.7rem;
  line-height: 2.1rem;
  text-align: center;
  border-radius: 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
}
.CloudStoreCode_circle {
  width: 100%;
  /* height: 8.8rem; */
  background-color: #fd800c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;
  margin-bottom: 0.77rem;
}

.CloudStoreCode_circle p {
  font-size: 0.85rem;
  color: #fff;
  margin-top: 0.77rem;
}
#CloudStoreCode .van-circle__text {
  color: #fff !important;
  font-size: 1.2rem !important;
}
.CloudStoreCode_content {
  width: 94%;
  height: 6rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin-top: 0.3rem;
}
.CloudStoreCode_content_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.CloudStoreCode_content_img {
  width: 2.5rem;
  height: 4.0625rem;
  margin-left: 0.6rem;
}
.CloudStoreCode_content_img img {
  width: 100%;
}
.CloudStoreCode_content_info {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
}
.CloudStoreCode_content_info p {
  margin-bottom: 0.3rem;
  width: 14rem;
  display: flex;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info p i {
  width: 100%;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info span {
  font-size: 0.8rem;
  color: #bfbdbd;
  width: 16.5rem;
  display: block;
  /* background-color: pink; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CloudStoreCode_content_info b {
  font-weight: normal;
}
.CloudStoreCode_content_right {
  color: #a9a9a9;
  margin-right: 0.77rem;
}
.CloudStoreCode_btn {
  display: block;
  width: 80%;
  height: 2.1rem;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  margin: 0 auto;
  margin-top: 4.7rem;
  line-height: 2.1rem;
  text-align: center;
  border-radius: 1.25rem;
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
}

.StoreQRCode_main {
  width: 95%;
  margin: 0 auto;
  margin-top: 3.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.77rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.04);
}
.StoreQRCode_content {
  width: 95%;
  display: flex;
  height: 3.8rem;
  background-color: #fff;
  margin: 0 auto;
  font-size: 0.9rem;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.StoreQRCode_content_img {
  width: 2.75rem;
  height: 3.5rem;
}
.StoreQRCode_content p {
  width: 5.5rem;
  letter-spacing: 0.08rem;
}
.StoreQRCode_content_img img {
  width: 100%;
}
.StoreQRCode_content i {
  position: absolute;
  right: 1rem;
  font-size: 1.1rem;
  color: #c7c7c7;
  font-weight: bold;
}
.StoreQRCode_content span {
  width: 70%;
  height: 100%;
  display: block;
  line-height: 3.8rem;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StoreQRCode_Mask {
  width: 18rem;
  height: 23.375rem;
  margin: 0 auto;
  margin-top: 8.8rem;
  position: relative;
}
.StoreQRCode_Mask img {
  width: 100%;
}
.StoreQRCode_mask_btn {
  display: block;
  width: 20rem;
  height: 2.2rem;
  margin: 0 auto;
  margin-top: 4rem;
  line-height: 2.2rem;
  text-align: center;
  background: linear-gradient(to right, #fc4a30, #fa2051);
  border-radius: 1.25rem;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
  color: #fff;
}
.StoreQRCode_Mask_codeimg {
  width: 8.5rem;
  height: 8.5rem;
  position: absolute;
  left: 27%;
  top: 31%;
}
.StoreQRCode_Mask_codeimg img {
  width: 100%;
}
.StoreQRCode_Mask_notice {
  width: 20rem;
  height: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  color: #fff;
  /* background-color: orangered; */
  position: absolute;
  left: -5%;
  top: 78%;
  transform: scale(0.9);
  text-align: center;
}
.StoreQRCode_Mask_notice p {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.StoreQRCode_Mask i {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-weight: bold;
}
</style>