<template>
  <div class="indexAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="index">
      <div class="indexContent">
        <div class="user">
          <div class="userinfo">
            <div class="avatar">
              <img :src="indexList.avatar" alt="头像" />
            </div>
            <div class="info">
              <span>{{indexList.name}}</span>
              <p>上次登录时间：{{indexList.last_time}}</p>
            </div>
          </div>
          <iframe
            allowtransparency="true"
            frameborder="0"
            width="180"
            height="36"
            scrolling="no"
            src="//tianqi.2345.com/plugin/widget/index.htm?s=3&z=2&t=0&v=0&d=3&bd=0&k=&f=&ltf=009944&htf=cc0000&q=1&e=1&a=1&c=54511&w=180&h=36&align=center"
          ></iframe>
        </div>
        <div class="money">
          <div class="moneyContent">
            <p>{{indexList.today_money}}</p>
            <span>今日营业额</span>
          </div>
          <div class="moneyContent">
            <p>{{indexList.on_line_trade}}</p>
            <span>线上交易笔数</span>
          </div>
          <div class="moneyContent">
            <p>{{indexList.offline_trade}}</p>
            <span>线下交易笔数</span>
          </div>
        </div>
        <div class="system">
          <h4>系统设置</h4>
          <nav>
            <a href="/#/QRCodeSetting">
              <div class="img">
                <img src="../../public/images/index/img3.png" alt="icon" />
              </div>
              <span>二维码设置</span>
            </a>
            <a href="/#/ShopSetting">
              <div class="img">
                <img src="../../public/images/index/img4.png" alt="icon" />
              </div>
              <span>门店设置</span>
            </a>
            <a href="/#/VipcardSetting">
              <div class="img">
                <img src="../../public/images/index/img5.png" alt="icon" />
              </div>
              <span>会员卡设置</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img6.png" alt="icon" />
              </div>
              <span>红包设置</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img7.png" alt="icon" />
              </div>
              <span>提成设置</span>
            </a>
          </nav>
        </div>
        <div class="system">
          <h4>管理中心</h4>
          <nav>
            <a href="/#/VipManage">
              <div class="img">
                <img src="../../public/images/index/img8.png" alt="icon" />
              </div>
              <span>会员管理</span>
            </a>
            <a href="/#/StaffManage">
              <div class="img">
                <img src="../../public/images/index/img9.png" alt="icon" />
              </div>
              <span>员工管理</span>
            </a>
            <a href="/#/LianChuangManage">
              <div class="img">
                <img src="../../public/images/index/img10.png" alt="icon" />
              </div>
              <span>联创管理</span>
            </a>
            <a href="/#/YunDianManage">
              <div class="img">
                <img src="../../public/images/index/img11.png" alt="icon" />
              </div>
              <span>云店管理</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img12.png" alt="icon" />
              </div>
              <span>财务管理</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img13.png" alt="icon" />
              </div>
              <span>消息管理</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img14.png" alt="icon" />
              </div>
              <span>设备管理</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img15.png" alt="icon" />
              </div>
              <span>点餐管理</span>
            </a>
            <!-- 777 -->
          </nav>
        </div>
        <div class="system">
          <h4>增值服务</h4>
          <nav>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img16.png" alt="icon" />
              </div>
              <span>营销设置</span>
            </a>
            <a href="javascript:void(0)">
              <div class="img">
                <img src="../../public/images/index/img17.png" alt="icon" />
              </div>
              <span>供应链商城</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import http from "@/util/http";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      // 首页数据
      indexList: ''
    };
  },
  mounted() {
    var token = localStorage.getItem("Authorization")
    console.log("token",token)
     http
      .request({
        url: "shop/index",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.indexList = res.data.data
        console.log("首页",this.indexList,res);
        var user = {
          username:this.indexList.name,
          avatar:this.indexList.avatar
        }
        localStorage.setItem("user",JSON.stringify(user))
        console.log("user",user)
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
  },
};
</script>
<style lang="scss" scoped>
.indexAll {
  width: 100%;
  height: 100%;
  display: flex;
}
// .Tabbar {
//   width: 9%;
//   height: 100%;
//   background: #121723;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 100;
//   .TabbarLogo {
//     width: 5.5rem;
//     height: 1.625rem;
//     margin: 0 auto;
//     margin-top: 3.125rem;
//     img {
//       width: 100%;
//     }
//   }
//   ul {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin-top: 2.5rem;
//     li {
//       width: 100%;
//       height: 3rem;
//       display: flex;
//       align-items: center;
//       color: #fff;
//       margin-bottom: 1.25rem;
//       .icon :nth-child(1) {
//         width: 0.875rem;
//         height: 0.75rem;
//         margin-left: 2.5rem;
//         img {
//           width: 100%;
//         }
//       }
//       .icon :nth-child(2) {
//         width: 0.875rem;
//         height: 0.75rem;
//         margin-left: 2.2rem;
//         img {
//           width: 100%;
//         }
//       }
//       .icon :nth-child(3) {
//         width: 0.875rem;
//         height: 0.875rem;
//         margin-left: 2.2rem;
//         img {
//           width: 100%;
//         }
//       }
//       .icon :nth-child(4) {
//         width: 1rem;
//         height: 0.875rem;
//         margin-left: 2.2rem;
//         img {
//           width: 100%;
//         }
//       }
//       span {
//         font-size: 0.875rem;
//         margin-left: 0.75rem;
//       }
//     }
//   }
//   .TabbarActive {
//     width: 100%;
//     background: linear-gradient(17deg, #fc4a30 0%, #fa2052 100%);
//     opacity: 0.77;
//     border-left: 0.4rem solid #fc0c35;
//     box-sizing: border-box;
//     .icon {
//       margin-left: -0.4rem;
//     }
//   }
//   .TabbarLogOut {
//     width: 100%;
//     height: 3rem;
//     display: flex;
//     align-items: center;
//     color: #fff;
//     margin-bottom: 1.25rem;
//     position: absolute;
//     bottom: 5%;
//     .icon {
//       width: 1rem;
//       height: 0.875rem;
//       margin-left: 2.2rem;
//       img {
//         width: 100%;
//       }
//     }
//     span {
//       font-size: 0.875rem;
//       margin-left: 0.75rem;
//       margin-top: 0.25rem;
//     }
//   }
// }
.index {
  // position: absolute;
  // left: 9.9rem;
  // top: 0;
  // flex: 1;
  position: absolute;
  width: 91%;
  // margin-left: 9.9rem;
  right: 0;
  top: 0;
  height: 100%;
  // right: 0;
  // top: 0;
  z-index: 100;
  background-image: url(../../public/images/index/background.png);
}
.indexContent {
  width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.user {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userinfo {
  width: 66%;
  display: flex;
  align-items: center;
  margin-top: 2.375rem;
  .avatar {
    width: 4.125rem;
    height: 4.125rem;
    margin-right: 0.875rem;
    border-radius: 50%;
    img {
      width: 100%;
    border-radius: 50%;
    }
  }
  .users {
    width: 100%;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000000;
    span {
      font-size: 1.25rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #3d363b;
    }
    p {
      color: #565656;
      font-size: 0.875rem;
      opacity: 0.5;
      margin-top: 0.75rem;
    }
  }
}
.money {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
  .moneyContent {
    width: 26%;
    height: 13rem;
    background-image: url(../../public/images/index/img2.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.8rem;
      font-family: Adobe Heiti Std;
      color: #caaa8a;
    }
    span {
      font-size: 1rem;
      font-family: PingFang SC;
      // font-weight: bold;
      color: #ffffff;
      opacity: 0.43;
      margin-top: 1.25rem;
      letter-spacing: 0.05rem;
    }
  }
}
.system {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-top: 2rem;
  h4 {
    font-size: 1.25rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #343434;
  }
  nav {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.375rem;
    flex-wrap: wrap;
    a {
      //    width: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin-right: 6.6rem;
      //    background:pink;
      .img {
        width: 2.5rem;
        height: 2.5rem;
        img {
          width: 100%;
        }
      }
      span {
        color: #343434;
        font-size: 0.75rem;
        margin-top: 0.625rem;
      }
    }
  }
}
</style>