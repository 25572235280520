import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login/Login'
import VipManage from '../views/VipManage/VipManage.vue'
import VipDetail from '../views/VipManage/VipDetail.vue'
import ShopSetting from '../views/ShopSetting/ShopSetting.vue'
import VipcardSetting from '../views/VipcardSetting/VipcardSetting.vue'
import PackagecardSettings from '../views/VipcardSetting/PackagecardSettings.vue'
import QRCodeSetting from '../views/QRCodeSetting/QRCodeSetting.vue'
import CollectionCode from '../views/QRCodeSetting/CollectionCode.vue'
import StaffCode from '../views/QRCodeSetting/StaffCode.vue'
import YunDianQrCode from '../views/QRCodeSetting/YunDianQrCode.vue'
import StaffManage from '../views/StaffManage/StaffManage'
import LianChuangManage from '../views/LianChuangManage/LianChuangManage'
import YunDianManage from '../views/YunDianManage/YunDianManage'
import SortManage from '../views/YunDianManage/SortManage'
import AddGoods from '../views/YunDianManage/AddGoods'
import EditGoods from '../views/YunDianManage/EditGoods'
import GoodsSpecs from '../views/YunDianManage/GoodsSpecs'









Vue.use(VueRouter)

const routes = [
  //  登录
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // 首页
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/',
    component: Index
  },
  {
    path: '/',
    redirect: Index
  },
  {
    path: '*',
    redirect: '/index'  //  斜杠后边无论输入什么  都跳转到index
  },
  //  首页=>会员管理
  {
    path: '/VipManage',
    name: 'VipManage',
    component: VipManage
  },
  //  首页=>会员管理=>会员详情
  {
    path: '/VipManage/VipDetail',
    name: 'VipDetail',
    component: VipDetail,
  },
  //  首页=>门店设置
  {
    path: '/ShopSetting',
    name: 'ShopSetting',
    component: ShopSetting
  },
  //  首页=>会员卡设置
  {
    path: '/VipcardSetting',
    name: 'VipcardSetting',
    component: VipcardSetting
  },
  //  首页=>会员卡设置=>套餐卡设置
  {
    path: '/PackagecardSettings',
    name: 'PackagecardSettings',
    component: PackagecardSettings
  },
  //首页=>二维码设置
  {
    path: '/QRCodeSetting',
    name: 'QRCodeSetting',
    component: QRCodeSetting
  },
  //首页=>二维码设置=>收款码
  {
    path: '/CollectionCode',
    name: 'CollectionCode',
    component: CollectionCode
  },
  //首页=>二维码设置=>员工码
  {
    path: '/StaffCode',
    name: 'StaffCode',
    component: StaffCode
  },
  //首页=>二维码设置=>云店二维码
  {
    path: '/YunDianQrCode',
    name: 'YunDianQrCode',
    component: YunDianQrCode
  },
  //首页=>员工管理
  {
    path: '/StaffManage',
    name: 'StaffManage',
    component: StaffManage
  },
   //首页=>联创管理
   {
    path: '/LianChuangManage',
    name: 'LianChuangManage',
    component: LianChuangManage
  },
    //首页=>云店管理
    {
      path: '/YunDianManage',
      name: 'YunDianManage',
      component: YunDianManage
    },
    //首页=>云店管理=>商品管理=>分类管理
    {
      path: '/SortManage',
      name: 'SortManage',
      component: SortManage
    },
     //首页=>云店管理=>商品管理=>新增商品
     {
      path: '/AddGoods',
      name: 'AddGoods',
      component: AddGoods
    },
       //首页=>云店管理=>商品管理=>编辑商品
       {
        path: '/EditGoods',
        name: 'EditGoods',
        component: EditGoods
      },
          //首页=>云店管理=>商品管理=>产品规格
          {
            path: '/GoodsSpecs',
            name: 'GoodsSpecs',
            component: GoodsSpecs
          },
    
  
]
// 避免到当前位置的冗余导航
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// router.beforeEach((to,from,next)=>{
//   var token  = JSON.parse(localStorage.getItem("user"));
//   if(!token){
//     if(to.path !== '/login'){
//       next ({ path:'/login'})
//     }else{
//       next()
//     }
//   }
//   else{
//     next()
//   }
// })

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
