<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>云店管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/YunDianManage">云店管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="javascript:void(0)">商品管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="javascript:void(0)">订单管理</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="CloudStoreManage_title">
            <div class="CloudStoreManage_title_main">
              <ul>
                <li>
                  <p>{{ Indexlist.today_money }}</p>
                  <span>今日营收</span>
                </li>
                <li>
                  <p>{{ Indexlist.today_num }}</p>
                  <span>今日订单</span>
                </li>
              </ul>
            </div>
            <a href="javascript:void(0)" class="iconfont icon-shezhi"></a>
          </div>
          <!-- 管理中心 -->
          <div class="CloudStoreManage_center">
            <h4>管理工具</h4>
            <ul>
              <!-- <li @click="handleBusinessStatement">
                <div class="CloudStoreManage_center_img">
                  <img src="../../../public/images/index/img58.png" alt="" />
                </div>
                <p>营业报表</p>
              </li> -->
              <li @click="handleShowGoodsManage">
                <div
                  class="CloudStoreManage_center_img"
                  style="width: 2.5rem; height: 1.75rem; margin-top: 0.25rem"
                >
                  <img src="../../../public/images/index/img62.png" alt="" />
                </div>
                <p>商品管理</p>
              </li>
              <li @click="handleShowOrderManage">
                <div class="CloudStoreManage_center_img">
                  <img src="../../../public/images/index/img60.png" alt="" />
                </div>
                <p>订单管理</p>
              </li>
              <!-- <li>
                <div
                  class="CloudStoreManage_center_img"
                  style="margin-top: 0.36rem"
                >
                  <img src="../../../public/images/index/img61.png" alt="" />
                </div>
                <p>店铺管理</p>
              </li> -->
              <!-- <li>
                <div class="CloudStoreManage_center_img">
                  <img src="../../../public/images/index/img54.png" alt="" />
                </div>
                <p>打印设置</p>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="right" v-show="Oneshow">
          <div class="GoodsManage">
            <div class="GoodsManageNav">
              <ul>
                <li
                  :class="current == index ? 'active' : ''"
                  v-for="(data, index) in Sortlist"
                  :key="data.id"
                  @click="handleChangeNav(index, data.id)"
                >
                  <span>{{ data.type_name }}</span>
                </li>
              </ul>
            </div>
            <div class="GoodsManageMenu">
              <div class="title">
                <p>{{ type_name }}</p>
              </div>
              <div
                class="main"
                v-if="
                  datalist != '' || datalist != 'undefined' || datalist != null
                "
              >
                <div class="content" v-for="data in datalist" :key="data.id">
                  <div class="info">
                    <div class="pic">
                      <img :src="data.image" alt="" />
                    </div>
                    <div class="detail">
                      <div class="tit">{{ data.title }}</div>
                      <div class="kucun">库存9999</div>
                      <div class="price">
                        ¥<span>{{ data.price }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="operation">
                    <p>
                      <a href="javascript:void(0)" @click="handlePageGoodsSpecs(data.id)">产品规格</a>
                      <a
                        href="javascript:void(0)"
                        @click="handlePageEditGoods(data.id)"
                        >编辑</a
                      >
                      <a
                        href="javascript:void(0)"
                        @click="handleonTheShelf(data.id)"
                        v-if="data.open == 1"
                        >下架</a
                      >
                      <a
                        href="javascript:void(0)"
                        @click="handleonTheShelf(data.id)"
                        v-if="data.open == 0"
                        >上架</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  datalist == '' || datalist == 'undefined' || datalist == null
                "
              >
                <div style="margin-top: -32rem">
                  <van-empty description="该分类没有商品信息~" />
                </div>
              </div>
            </div>
            <div class="btn">
              <div class="sortManage" @click="handlePageSortManage">
                <van-icon name="apps-o" size="1.5rem" />
                <span>分类管理</span>
              </div>
              <div class="addGoods" @click="handlePageAddGoods">
                <van-icon name="plus" size="1.5rem" />
                <span>新增商品</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单管理 -->
        <div class="right" v-show="Twoshow" style="background: #f4f4f4">
          <div class="OrderTab">
            <van-tabs v-model="tabActive" title-active-color="red">
              <van-tab title="全部订单">
                <div class="Ordermain">
                  <div class="Ordercontent">
                    <div class="Ordertop">
                      <div class="Orderleft">
                        <span>外送</span>
                        <nav>取件码: 1000244</nav>
                      </div>
                      <div class="Orderright">
                        <p>订单待付款</p>
                        <van-icon name="arrow" size="0.95rem" />
                      </div>
                    </div>
                    <div class="OrderDetail">
                      <div class="OrderDetailmain">
                        <div class="OrderDetailcontent">
                          <div class="Orderpic">
                            <img
                              src="../../../public/images/index/img1.png"
                              alt=""
                            />
                          </div>
                          <span>乌梅番茄</span>
                        </div>
                        <div class="OrderDetailcontent">
                          <div class="Orderpic">
                            <img
                              src="../../../public/images/index/img1.png"
                              alt=""
                            />
                          </div>
                          <span>乌梅番茄</span>
                        </div>
                      </div>
                      <div class="Orderprice">
                        <p>共1份:</p>
                        <nav>¥<span>10.91</span></nav>
                      </div>
                    </div>
                    <div class="OrderBtn">
                      <a href="javascript:void(0)">联系顾客</a>
                      <a href="javascript:void(0)">补打小票</a>
                      <a href="javascript:void(0)">处理订单</a>
                    </div>
                  </div>

                  <div class="Ordercontent">
                    <div class="Ordertop">
                      <div class="Orderleft">
                        <span>外送</span>
                        <nav>取件码: 1000244</nav>
                      </div>
                      <div class="Orderright">
                        <p>订单待付款</p>
                        <van-icon name="arrow" size="0.95rem" />
                      </div>
                    </div>
                    <div class="OrderDetail">
                      <div class="OrderDetailmain">
                        <div class="OrderDetailcontent">
                          <div class="Orderpic">
                            <img
                              src="../../../public/images/index/img1.png"
                              alt=""
                            />
                          </div>
                          <span>乌梅番茄</span>
                        </div>
                        <div class="OrderDetailcontent">
                          <div class="Orderpic">
                            <img
                              src="../../../public/images/index/img1.png"
                              alt=""
                            />
                          </div>
                          <span>乌梅番茄</span>
                        </div>
                      </div>
                      <div class="Orderprice">
                        <p>共1份:</p>
                        <nav>¥<span>10.91</span></nav>
                      </div>
                    </div>
                    <div class="OrderBtn">
                      <a href="javascript:void(0)">联系顾客</a>
                      <a href="javascript:void(0)">补打小票</a>
                      <a href="javascript:void(0)">处理订单</a>
                    </div>
                  </div>
                </div>
              </van-tab>
              <van-tab title="待处理">内容 2</van-tab>
              <van-tab title="已完成">内容 3</van-tab>
              <van-tab title="已关单">内容 4</van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import {
  Popup,
  Dialog,
  Toast,
  Pagination,
  Empty,
  Icon,
  Circle,
  Tab,
  Tabs,
} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Circle);
Vue.use(Icon);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>员工管理=>添加员工
      Oneshow: false,
      // 首页=>员工管理=>修改员工
      Twoshow: false,
      // 云店首页数据
      Indexlist: "",
      // 菜品分类高亮
      current: 0,
      // 产品首页查询
      Sortlist: "",
      // 产品列表切换查询产品列表
      datalist: "",
      // 当前分类名称
      type_name: "",
      // 全部订单tab状态
      tabActive: 0,
    };
  },
  mounted() {
    this.Oneshow = this.$route.params.Oneshow;
    console.log(this.Oneshow);
    // 云店首页
    http
      .request({
        url: "/shop/yundianIndex",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.Indexlist = res.data.data;
        console.log(132, res);
      });
    // 产品首页查询
    http
      .request({
        url: "/shop/yundianGoodsIndex",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // this.datalist = res.data.data;
        this.Sortlist = res.data.data.category;
        console.log(77, this.Sortlist);
        var id = this.Sortlist[0].id;
        http
          .request({
            url: "/shop/yundianGoodsIndex",
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              type_id: id,
            },
          })
          .then((res) => {
            this.datalist = res.data.data.list;
            this.type_name = res.data.data.type_name;
            console.log("分类产品查询", this.datalist, this.type_name);
          });
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    //   显示商品管理页面
    handleShowGoodsManage() {
      this.Twoshow = false;
      this.Oneshow = true;
    },
    // 显示订单管理页面
    handleShowOrderManage() {
      this.Oneshow = false;
      this.Twoshow = true;
    },
    // 跳转商品管理=>分类管理页面
    handlePageSortManage() {
      this.$router.push("/SortManage");
    },
    // 跳转商品管理=>新增商品页面
    handlePageAddGoods() {
      this.$router.push("/AddGoods");
    },
    // 跳转商品管理=>编辑商品页面
    handlePageEditGoods(id) {
      this.$router.push({
        name: "EditGoods",
        query: { id: id },
      });
    },
    // 点击产品分类
    handleChangeNav(index, id) {
      this.current = index;
      console.log(id);
      http
        .request({
          url: "/shop/yundianGoodsIndex",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            type_id: id,
          },
        })
        .then((res) => {
          this.datalist = res.data.data.list;
          this.type_name = res.data.data.type_name;
          console.log("分类产品查询", this.datalist, this.type_name);
        });
    },
    // 上下架产品
    handleonTheShelf(id) {
      // console.log(this.Sortlist[0].id)
      var typeid = this.Sortlist[0].id
      http
        .request({
          url: `/shop/yundianGoodsOpen?id=${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          http
        .request({
          url: "/shop/yundianGoodsIndex",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            type_id: typeid,
          },
        })
        .then((res) => {
          this.datalist = res.data.data.list;
          this.type_name = res.data.data.type_name;
          console.log("分类产品查询", this.datalist, this.type_name);
        });
        this.current = 0
          Toast(res.data.msg);
        });
    },
    // 跳转产品规格页面
    handlePageGoodsSpecs(id){
       this.$router.push({
        name: "GoodsSpecs",
        query: { id: id },
      });
    }
  },
  computed: {
    Onetext() {
      return this.OnecurrentRate.toFixed(0) + "%";
    },
    Twotext() {
      return this.TwocurrentRate.toFixed(0) + "%";
    },
  },
};
</script>
<style lang="scss" scoped>
.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
// 左右内容
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    background: #fff;
    position: relative;
    // 左边
    .CloudStoreManage_title {
      width: 100%;
      height: 12.875rem;
      background: url(../../../public/images/index/img56.png);
      background-size: 100% 100%;
      position: relative;
    }
    .CloudStoreManage_title_content {
      width: 93%;
      height: 5rem;
      /* background: pink; */
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding-top: 1.3rem;
    }
    .CloudStoreManage_title_img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
    .CloudStoreManage_title_img img {
      width: 100%;
       width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
    .CloudStoreManage_title_info {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0.77rem;
    }
    .CloudStoreManage_title_info nav {
      width: 70%;
      font-size: 1rem;
      color: #fff;
      margin-bottom: 0.3rem;
      /* background: pink; */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .CloudStoreManage_title_info p {
      width: 90%;
      /* background: pink; */
      font-size: 0.75rem;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .CloudStoreManage_title_main {
      /* width: 22.5rem; */
      width: 80%;
      height: 6.3125rem;
      margin: 0 auto;
      background: url(../../../public/images/index/img57.png);
      background-size: 100% 100%;
      //   margin-top: 0.25rem;
      position: absolute;
      bottom: -0.1rem;
      left: 10%;
    }
    .CloudStoreManage_title_main ul {
      width: 90%;
      height: 5rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      /* background: skyblue; */
      padding-top: 1.2rem;
      //   background: pink;
    }
    .CloudStoreManage_title_main ul li {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .CloudStoreManage_title_main ul li:nth-child(1) {
      background: url(../../../public/images/index/img63.png);
      background-repeat: no-repeat;
      background-size: 2px 100%;
      background-position: right center;
    }
    .CloudStoreManage_title_main ul li p {
      font-size: 1rem;
      color: #feefce;
      font-weight: bold;
    }
    .CloudStoreManage_title_main ul li span {
      font-size: 0.85rem;
      color: #feefce;
      margin-top: 0.5rem;
    }
    .CloudStoreManage_title .icon-shezhi {
      position: absolute;
      right: 1rem;
      top: 1.4rem;
      font-size: 1.2rem;
      color: #fff;
    }
    .CloudStoreManage_center {
      width: 95%;
      /* height: 11.5rem; */
      /* background: #fff; */
      margin: 0 auto;
      margin-top: 0.77rem;
      border-radius: 0.7rem;
    }
    .CloudStoreManage_center h4 {
      padding-top: 1rem;
      margin-left: 1rem;
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }
    .CloudStoreManage_center ul {
      width: 100%;
      /* height: 4.2rem; */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto;
      margin-top: 0.77rem;
      flex-wrap: wrap;
    }
    .CloudStoreManage_center ul li {
      width: 25%;
      height: 4.1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75rem;
      margin-top: 0.88rem;
      margin-bottom: 1rem;
    }
    .CloudStoreManage_center ul li .CloudStoreManage_center_img {
      width: 2.125rem;
      height: 2.125rem;
      margin-bottom: 0.45rem;
    }
    .CloudStoreManage_center ul li .CloudStoreManage_center_img img {
      width: 100%;
          width: 2.125rem;
      height: 2.125rem;
    }
    .CloudStoreManage_center ul li p {
      color: #5b5959;
      margin-top: 0.3rem;
      letter-spacing: 0.04rem;
    }
  }
}
.VipcardSettingMain .left ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  .GoodsManage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .GoodsManageNav {
      width: 20%;
      height: 46rem;
      background-color: #f4f4f4;
      margin-bottom: 4rem;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: scroll;
        li {
          width: 100%;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          span {
            font-size: 0.95rem;
            text-align: center;
          }
        }
        .active {
          box-sizing: border-box;
          background: #fff;
          span {
            width: 90%;
            font-weight: bold;
            border-left: 4px solid red;
            box-sizing: border-box;
            margin-right: 4px;
          }
        }
      }
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ul::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .GoodsManageMenu {
      height: 46rem;
      width: 80%;
      background: pink;
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
      .title {
        width: 100%;
        height: 3.6rem;
        background: #fff;
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        font-weight: bold;
        p {
          width: 90%;
          margin: 0 auto;
        }
      }
      .main {
        height: 57rem;
        width: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .content {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          padding: 1rem 0;
          background: #fff;
          border-top: 2px solid #f7f7f7;
          .info {
            width: 100%;
            display: flex;
            align-items: center;
            .pic {
              width: 4.2rem;
              height: 4.2rem;
              border-radius: 0.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              // background: pink;
              img {
                width: 4.2rem;
                height: 4.2rem;
                width: 100%;
              }
            }
            .detail {
              flex: 1;
              display: flex;
              flex-direction: column;
              margin-left: 2rem;
              .tit {
                font-size: 1rem;
              }
              .kucun {
                margin: 0.3rem 0;
                font-size: 0.85rem;
                color: #999999;
              }
              .price {
                color: #e95902;
                font-size: 0.85rem;
                span {
                  font-size: 1rem;
                  margin-left: 0.2rem;
                }
              }
            }
          }
          .operation {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 0.77rem;
            p {
              width: 78%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            a {
              padding: 0.3rem 2rem;
              font-size: 0.9rem;
              border-radius: 0.35rem;
            }
            a:nth-child(1) {
              color: #40cf8f;
              background: #d7f3e1;
              border: 1px solid #40cf8f;
            }
            a:nth-child(2) {
              color: #ed4b47;
              background: #feeff4;
              border: 1px solid #ed4b47;
            }
            a:nth-child(3) {
              color: #f6a963;
              background: #f9f8e7;
              border: 1px solid #f6a963;
            }
          }
        }
      }
      .main::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }
  .btn {
    width: 100%;
    height: 4rem;
    background: pink;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sortManage {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1abd6a;
      color: #fff;
      font-size: 1rem;
      span {
        margin-left: 0.5rem;
      }
    }
    .addGoods {
      width: 75%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fa535a;
      color: #fff;
      font-size: 1rem;
      span {
        margin-left: 0.5rem;
      }
    }
  }
}

.OrderTab {
  width: 100%;
  .Ordermain {
    width: 96%;
    margin: 0 auto;
    margin-top: 0.77rem;
    .Ordercontent {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 0.5rem;
      margin-top: 0.77rem;
      .Ordertop {
        width: 96%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid #f7f7f7;
        .Orderleft {
          display: flex;
          align-items: center;
          span {
            display: flex;
            font-size: 0.85rem;
            color: #fff;
            padding: 0.25rem 1.2rem;
            background: #fc4435;
            justify-content: center;
            align-items: center;
            border-radius: 0.35rem;
          }
          nav {
            font-size: 0.95rem;
            font-weight: bold;
            margin-left: 1rem;
          }
        }
        .Orderright {
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 0.85rem;
          p {
            margin-right: 0.36rem;
          }
        }
      }
      .OrderDetail {
        width: 96%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 0;
        border-bottom: 1px solid #f7f7f7;
        .OrderDetailmain {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .OrderDetailcontent {
            width: 22%;
            height: 9rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
            margin-right: 1.1rem;
            .Orderpic {
              width: 4.3rem;
              height: 4.3rem;
              margin-top: 1.5rem;
              img {
                width: 4rem;
                height: 4rem;
              }
            }
            span {
              // flex: 1;
              height: 2.2rem;
              width: 90%;
              text-align: center;
              font-size: 0.85rem;
              margin-top: 0.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        .Orderprice {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            font-size: 0.85rem;
            color: #999999;
          }
          nav {
            display: flex;
            align-items: center;
            font-size: 0.85rem;
            font-weight: bold;
            margin-left: 0.5rem;
            span {
              font-size: 1.2rem;
              margin-left: 0.36rem;
            }
          }
        }
      }
      .OrderBtn {
        width: 96%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.77rem 0;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.375rem 1rem;
          font-size: 0.9rem;
          margin-left: 0.77rem;
          border-radius: 0.36rem;
        }
        a:nth-child(1) {
          background: #daf0e0;
          color: #27bf65;
          border: 1px solid #27bf65;
        }
        a:nth-child(2) {
          background: #fdf6ec;
          color: #ff911e;
          border: 1px solid #ff911e;
        }
        a:nth-child(3) {
          background: #808080;
          color: #ffffff;
          border: 1px solid #757575;
        }
      }
    }
  }
}
</style>