<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token:""
    };
  },
  mounted() {
    this.token = localStorage.getItem("Authorization")
    if(this.token == '' || this.token == undefined || this.token == null){
      this.$router.push("/")
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 0px) and (max-width: 319px) {
  html {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
  html {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 374px) {
  html {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 385px) {
  html {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 386px) and (max-width: 392px) {
  html  {
    font-size: 16.44px !important;
  }
}
@media screen and (min-width: 393px) and (max-width: 400px) {
  html {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 414px) {
  html {
    font-size: 17.48px !important;
  }
}
@media screen and (min-width: 415px) and (max-width: 749px) {
  html {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 750px) and (max-width: 799px) {
  html {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1025px) {
  html {
    font-size: 18px !important;
  }
}
* {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}
input{
  outline:none
}
</style>
