<template>
  <div class="VipcardSettingAll">
    <Nav type="1" v-on:handlePageIndex="handlePageIndex"></Nav>
    <div class="VipcardSetting">
      <UserTitle>
        <template v-slot:title>联创管理</template>
      </UserTitle>
      <div class="TabArea">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="/#/LianChuangManage">联创管理</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Oneshow == true"
            ><a href="javascript:void(0)">联创添加</a></el-breadcrumb-item
          >
          <el-breadcrumb-item v-show="Twoshow == true"
            ><a href="javascript:void(0)">联创修改</a></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="VipcardSettingMain">
        <div class="left">
          <div class="mains">
            <div class="staffSupervise_search">
              <input
                type="text"
                placeholder="一键查找联创……"
                v-model="KeyWord"
                maxlength="18"
              />
              <i @click="handleSearchKey">搜索</i>
            </div>
            <!-- 中间内容 -->
            <div class="staffSupervise_main">
              <div
                class="staffSupervise_content"
                v-for="data in datalist"
                :key="data.id"
                v-show="datalist !=''&& datalist!=null && datalist!='undefined'"
              >
                <div class="userinfo">
                  <div class="time">
                    <p>添加时间：{{ data.create_time }}</p>
                    <!-- <p>状态：<span class="staffSuperviseOk">正常</span></p> -->
                  </div>
                  <div class="main">
                    <div class="content">
                      <div class="portrait">
                        <img
                          src="../../../public/images/index/img48.png"
                          alt=""
                        />
                      </div>
                      <div class="info">
                        <div class="name">
                          <b>{{ data.username }}</b>
                          <i>联创</i>
                        </div>
                        <div class="phone">
                          <span> 手机号：{{ data.mobile }} </span>
                        </div>
                      </div>
                    </div>
                    <div class="userBtn">
                      <a
                        href="javascript:void(0)"
                        @click="handleDelStaff(data.id)"
                        >删除</a
                      >
                      <a
                        href="javascript:void(0)"
                        class="modifys"
                        @click="handleModifysStaff(data.id)"
                        >修改</a
                      >
                    </div>
                  </div>
                </div>
                <div class="tag">
                  <div class="con">
                    <p>基础信息：</p>
                    <nav>
                      <span>分红比例{{ data.bonus_num }}%</span>
                    </nav>
                  </div>
                </div>
              </div>
              <div v-show="datalist ==''|| datalist==null || datalist=='undefined'">
                  <van-empty description="数据为空~" />
              </div>
            </div>
            <div class="pagenation">
              <van-pagination
                v-model="page"
                mode="simple"
                :total-items="total"
                @change="handleChangePage"
              />
            </div>
            <div
              class="staffSupervise_btn"
              @click="
                Twoshow = false;
                Oneshow = true;
                username = '';
                pwd = '';
                bonus_num = '';
                mobile = '';
              "
            >
              <div class="pic">
                <img src="../../../public/images/index/img49.png" alt="" />
              </div>
              <span class="addStaffSupervise">添加联创</span>
            </div>
          </div>
        </div>
        <div class="right" v-show="Oneshow">
          <div class="addWorker_userinfo">
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>姓名</p>
                <input
                  type="text"
                  placeholder="请输入姓名"
                  v-model="username"
                />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>手机号</p>
                <input
                  type="number"
                  placeholder="请输入手机号"
                  v-model="mobile"
                />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创密码</p>
                <input type="number" placeholder="请输入密码" v-model="pwd" />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创分红权比例</p>
                <input
                  type="number"
                  placeholder="请输入数值"
                  v-model="bonus_num"
                  style="width: 16.2rem; margin-right: -9rem"
                /><span>%</span>
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创总分红权</p>
                <span>100 %</span>
              </div>
            </div>
            <div class="staffmodify" style="border: none">
              <div class="staffmodify_content">
                <p>剩余分红权比例</p>
                <span>{{ surplus }} %</span>
              </div>
            </div>
          </div>
          <!-- <div class="addWorker_bind">
            <div class="addWorker_bindContent">
              <p>会员卡提成绑定</p>
              <b>请选择</b>
              <i class="iconfont icon-youjiantou"></i>
            </div>
            <div class="addWorker_bindContent">
              <p>消费项目提成绑定</p>
              <b>方案一</b>
              <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>

          <div class="addWorker_power">
            <i>权限设置</i>
            <p>
              <input id="one" type="checkbox" /><label for="one">开单</label>
              <input id="two" type="checkbox" /><label for="two">办卡</label>
              <input id="three" type="checkbox" /><label for="three"
                >扫码核销</label
              >
              <input id="four" type="checkbox" /><label for="four"
                >修改订单</label
              >
            </p>
          </div> -->
          <div class="addWorker_btn" @click="handleAddStaff">添加</div>
        </div>

        <!-- 修改员工信息 -->
        <div class="right" v-show="Twoshow">
          <div class="addWorker_userinfo">
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>姓名</p>
                <input
                  type="text"
                  placeholder="请输入姓名"
                  v-model="Newusername"
                />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>手机号</p>
                <input
                  type="number"
                  placeholder="请输入手机号"
                  v-model="Newmobile"
                />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创密码</p>
                <input
                  type="number"
                  placeholder="请输入密码"
                  v-model="Newpwd"
                />
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创分红权比例</p>
                <input
                  type="number"
                  placeholder="请输入数值"
                  v-model="Newbonus_num"
                  style="width: 16.2rem; margin-right: -9rem"
                /><span>%</span>
              </div>
            </div>
            <div class="staffmodify">
              <div class="staffmodify_content">
                <p>联创总分红权</p>
                <span>100 %</span>
              </div>
            </div>
            <div class="staffmodify" style="border: none">
              <div class="staffmodify_content">
                <p>剩余分红权比例</p>
                <span>{{ surplus }} %</span>
              </div>
            </div>
          </div>
          <div class="addWorker_btn" @click="handleSaveModify">修改</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import UserTitle from "@/components/UserTitle";
import Vue from "vue";
import { Popup, Dialog, Toast, Pagination , Empty} from "vant";
import http from "@/util/http";

Vue.use(Toast);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Popup);
export default {
  components: {
    Nav,
    UserTitle,
  },
  data() {
    return {
      // 首页=>员工管理=>添加员工
      Oneshow: false,
      // 首页=>员工管理=>修改员工
      Twoshow: false,
      // 分页
      page: 1,
      // 一共多少页
      totalPage: "",
      // 每页多少个数据
      per_page: "",
      // 一共多少条数据
      total: 0,
      // 联创数据
      datalist: "",
      // 关键词
      KeyWord: "",
      // 添加联创名姓名
      username: "",
      // 添加联创电话号码
      mobile: "",
      // 添加联创密码
      pwd: "",
      // 添加联创分红权
      bonus_num: "",

      // 修改联创名姓名
      Newusername: "",
      // 修改联创电话号码
      Newmobile: "",
      // 修改联创密码
      Newpwd: "",
      // 修改联创分红权
      Newbonus_num: "",
      // 要修改的员工id
      id: "",
      //   剩余的分红权比例
      surplus: "",
    };
  },
  mounted() {
    http
      .request({
        url: "/shop/foundersIndex",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          key: this.KeyWord,
          page: this.page,
        },
      })
      .then((res) => {
        this.datalist = res.data.data.data;
        this.total = res.data.data.total;
        console.log("联创列表", this.datalist, this.total);
        this.id = res.data.data.data[0].id;
        console.log("id", this.id);
      });

    http
      .request({
        url: "/shop/foundersAdd",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.surplus = res.data.data.bonus;
        console.log(132, this.surplus);
      });
  },
  methods: {
    // 左侧导航栏点击跳转首页
    handlePageIndex(){
      this.$router.push('/index')
    },
    // 点击分页
    handleChangePage() {
      console.log(this.page);
      http
        .request({
          url: "/shop/foundersIndex",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            key: this.KeyWord,
            page: this.page,
          },
        })
        .then((res) => {
          // if (page > 1 && datalist.length > 0) {
          // datalist = datalist.concat(res.data.data.data);
          // } else {
          this.datalist = [];
          this.datalist = res.data.data.data;
          // }
          this.totalPage = res.data.data.last_page;
          this.per_page = res.data.data.per_page;
          console.log(778, this.datalist);
        });
    },
    // 添加员工
    handleAddStaff() {
      if (this.username == "") {
        Toast("请输入姓名");
        return;
      } else if (this.mobile == "") {
        Toast("请输入手机号");
        return;
      } else if (this.pwd == "") {
        Toast("请输入密码");
        return;
      } else if (this.bonus_num == "") {
        Toast("请输入联创分红权比例");
        return;
      }
      http
        .request({
          url: "/shop/foundersAdd",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            username: this.username,
            mobile: this.mobile,
            password: this.pwd,
            bonus_num: this.bonus_num,
          },
        })
        .then((res) => {
          console.log("添加联创", res);
          if (res.data.code == 0) {
            Toast(res.data.msg);
            return;
          } else {
            Toast(res.data.msg);
            this.Oneshow = false;
            http
              .request({
                url: "/shop/foundersAdd",
                method: "get",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                this.surplus = res.data.data.bonus;
                console.log(132, this.surplus);
              });

            http
              .request({
                url: "/shop/foundersIndex",
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  key: this.KeyWord,
                  page: 1,
                },
              })
              .then((res) => {
                this.datalist = res.data.data.data;
                this.total = res.data.data.total;
                console.log("联创列表", this.datalist, this.total);
              });
          }
        });
    },
    // 修改查询员工信息
    handleModifysStaff(id) {
      this.Oneshow = false;
      this.Twoshow = true;
      this.id = id;
      console.log(this.id);
      http
        .request({
          url: `/shop/foundersEdit?id=${id}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          (this.Newusername = res.data.data.info.username),
            (this.Newmobile = res.data.data.info.mobile),
            (this.Newpwd = res.data.data.info.password);
          (this.Newbonus_num = res.data.data.info.bonus_num),
            console.log(
              "员工修改查询",
              this.Newusername,
              this.Newmobile,
              this.Newpwd,
              this.Newbonus_num
            );
        });
    },
    // 保存员工修改信息
    handleSaveModify() {
      console.log(77);
      http
        .request({
          url: "/shop/foundersEdit",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            username: this.Newusername,
            mobile: this.Newmobile,
            password: this.Newpwd,
            bonus_num: this.Newbonus_num,
            id: this.id,
          },
        })
        .then((res) => {
          console.log("联创保存修改", res);
          if (res.data.code == 0) {
            Toast(res.data.msg);
            return;
          } else {
            Toast(res.data.msg);
            this.Twoshow = false;
            http
              .request({
                url: "/shop/foundersIndex",
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  key: this.KeyWord,
                  page: this.page,
                },
              })
              .then((res) => {
                this.datalist = res.data.data.data;
                this.total = res.data.data.total;
                console.log("联创列表", this.datalist, this.total);
              });

            http
              .request({
                url: "/shop/foundersAdd",
                method: "get",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                this.surplus = res.data.data.bonus;
                console.log(132, this.surplus);
              });
          }
        });
    },
    // 删除员工信息
    handleDelStaff(id) {
      Dialog.confirm({
        title: "您确定删除此数据吗?",
      })
        .then(() => {
          // on confirm
          http
            .request({
              url: "/shop/foundersDel",
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              data:{
                  id: id
              }
            })
            .then((res) => {
              console.log("员工删除", res);
              Toast(res.data.msg);
              http
              .request({
                url: "/shop/foundersIndex",
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  key: this.KeyWord,
                  page: this.page,
                },
              })
              .then((res) => {
                this.datalist = res.data.data.data;
                this.total = res.data.data.total;
                console.log("联创列表", this.datalist, this.total);
              });

            http
              .request({
                url: "/shop/foundersAdd",
                method: "get",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                this.surplus = res.data.data.bonus;
                console.log(132, this.surplus);
              });
            });
        })
        .catch(() => {
          // on cancel
          console.log("点击取消");
        });
    },
    // 搜索查询员工
    handleSearchKey() {
      http
        .request({
          url: "/shop/foundersIndex",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            key: this.KeyWord,
            page: this.page,
          },
        })
        .then((res) => {
          this.datalist = res.data.data.data;
          this.total = res.data.data.total;
          console.log("联创列表", this.datalist, this.total);
          this.id = res.data.data.data[0].id;
          console.log("id", this.id);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 员工修改页面 */
#staffmodify {
  background-color: #f5f5f5;
}

.staffmodify {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f7f7f7;
  margin-top: 3rem;
}
.staffmodify .staffmodify_content {
  width: 92%;
  height: 2.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -2rem;
}
.staffmodify .staffmodify_content p {
  font-size: 0.875rem;
  letter-spacing: 0.03rem;
}
.staffmodify .staffmodify_content input {
  height: 1.5rem;
  width: 18rem;
  border: none;
  background: #f4f4f4;
  text-align: left;
  font-size: 0.875rem;
  text-indent: 0.5rem;
}

.staffmodify .staffmodify_content .fenhong {
  display: flex;
}
.staffmodify .staffmodify_content .fenhong input {
  width: 9.2rem;
}
.staffmodify .staffmodify_content .fenhong p {
  margin-left: 0.8rem;
  font-size: 0.875rem;
}

.staffmodify .staffmodify_content .fenhongquan {
  display: flex;
  align-items: center;
}
.staffmodify .staffmodify_content .fenhongquan span {
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
}
.staffmodify .staffmodify_content .fenhongquan p {
  margin-left: 0.8rem;
}
.staffmodify .staffmodify_content input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #d4d4d4;
  letter-spacing: 0.04rem;
  // text-align: center;
}
.staffmodify .staffmodify_content input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d4d4d4;
  letter-spacing: 0.04rem;
  // text-align: center;
}
.staffmodify .staffmodify_content input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d4d4d4;
  letter-spacing: 0.04rem;
  // text-align: center;
}
.staffmodify .staffmodify_content input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #d4d4d4;
  letter-spacing: 0.04rem;
  // text-align: center;
}
.staffmodify_bottom {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10rem;

  align-items: center;
  justify-content: center;
}
.staffmodify_bottom a {
  text-decoration: none;
  text-align: center;
  line-height: 2.75rem;
  width: 18.5rem;
  height: 2.75rem;
  border-radius: 1.666667rem;
  background: linear-gradient(to right, #ff5f00, #ff4100, #ff3f00);
  border: none;
  color: #fff;
  letter-spacing: 0.5rem;
  font-size: 1rem;
}

.addWorker_userinfo {
  width: 93%;
  margin: 0 auto;
  margin-top: 3rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.35rem;
}
.addWorker_userinfoContent {
  width: 93%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0.7rem 0;
  font-size: 0.9rem;
  position: relative;
  border-bottom: 1px solid #f7f7f7;
  //   background: pink;
}
.addWorker_userinfoContent p {
  width: 5rem;
}
.addWorker_userinfoContent input {
  flex: 1;
  height: 1.8rem;
  border: none;
}
.addWorker_userinfoContent i {
  font-size: 0.875rem;
  position: absolute;
  right: 0.1rem;
  color: #999999;
}
.addWorker_bind {
  width: 93%;
  margin: 0 auto;
  margin-top: 0.77rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.35rem;
}
.addWorker_bindContent {
  width: 93%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0.88rem 0;
  font-size: 0.9rem;
  position: relative;
  border-bottom: 0.0625rem solid #f7f7f7;
}
.addWorker_bindContent p {
  width: 40%;
}
.addWorker_bindContent b {
  font-weight: normal;
  flex: 1;
  text-align: right;
  margin-right: 0.5rem;
  color: #999999;
}
.addWorker_power {
  width: 93%;
  margin: 0 auto;
  padding-top: 0.8rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
  background: #fff;
  margin-top: 0.77rem;
}
.addWorker_power i {
  display: block;
  width: 93%;
  margin: 0 auto;
  font-style: normal;
}
.addWorker_power p {
  width: 93%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  // background: pink;
}
.addWorker_power p label {
  margin-left: -4rem;
}
.addWorker_btn {
  width: 70%;
  margin: 0 auto;
  margin-top: 2.5rem;
  height: 2.75rem;
  background: linear-gradient(93deg, #ff3f00 39%, #ff6000 100%);
  opacity: 0.8;
  border-radius: 1.25rem;
  font-size: 1;
  font-family: PingFang SC;
  color: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1rem;
}

.VipcardSettingAll {
  width: 100%;
  height: 100%;
  display: flex;
}
.VipcardSetting {
  position: absolute;
  width: 91%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  background: #f7f8fa;
}
.TabArea {
  width: 100%;
  padding: 1rem 0;
  margin-top: 3.125rem;
  .el-breadcrumb {
    margin-left: 1.25rem;
    .el-breadcrumb-item {
      font-size: 10rem;
    }
  }
}
.VipcardSettingMain {
  width: 91.5%;
  margin: 0 auto;
  display: flex;
  //   justify-content: space-between;
  .left {
    width: 40%;
    height: 50rem;
    background: #f9f8f8;
    margin-right: 5rem;
    background: #fff;
    position: relative;
    // 左边
    .staffSupervise_search {
      width: 92%;
      height: 2rem;
      margin: 0 auto;
      /* padding-top: 2.8rem; */
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 1rem;
    }
    .staffSupervise_search input {
      height: 1.875rem;
      width: 100%;
      border-radius: 1.25rem;
      border: none;
      background: #f4f4f4;
      text-indent: 2.4rem;
      font-size: 0.9rem;
    }
    .staffSupervise_search i {
      font-size: 0.85rem;
      color: #7e7e7e;
      position: absolute;
      right: 2.2rem;
      top: 0.45rem;
      font-style: normal;
    }
    .staffSupervise_search input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #c8c7c7;
      letter-spacing: 0.03rem;
      text-indent: 2.4rem;
      font-size: 0.85rem;
    }
    .staffSupervise_search input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #c8c7c7;
      letter-spacing: 0.03rem;
      text-indent: 2.4rem;
    }
    .staffSupervise_search input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #c8c7c7;
      letter-spacing: 0.03rem;
      text-indent: 2.4rem;
    }
    .staffSupervise_search input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #c8c7c7;
      letter-spacing: 0.03rem;
      text-indent: 2.4rem;
    }
    .staffSupervise_main {
      width: 94%;
      height: 37rem;
      margin: 0 auto;
      background-color: #f4f4f4;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      //   justify-content: center;
      overflow: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    .pagenation {
      width: 100%;
      height: 3rem;
      float: right;
      margin-top: 1rem;
      margin-bottom: 6rem;

      // background: pink;
    }
    .staffSupervise_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 0.4rem;
      margin-bottom: 0.2rem;
    }
    .staffSupervise_content .userinfo {
      width: 100%;
      margin: 0 auto;
      background-color: #fff;
    }

    .staffSupervise_content .userinfo .time {
      width: 94%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      padding: 0.4rem 0;
      border-bottom: 0.0625rem solid #f5f5f5;
    }
    .staffSupervise_content .userinfo .main {
      width: 94%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.47rem 0;
    }
    .staffSupervise_content .userinfo .content {
      width: 60%;
      display: flex;
      align-items: center;
    }
    .staffSupervise_content .userinfo .portrait {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin-right: 0.35rem;
    }
    .staffSupervise_content .userinfo .portrait img {
      width: 100%;
      border-radius: 50%;
    }
    .staffSupervise_content .userinfo .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .staffSupervise_content .userinfo .info .name {
      width: 100%;
      display: flex;
      align-items: center;
      align-items: flex-end;
    }
    .staffSupervise_content .userinfo .info .name b {
      font-size: 0.9rem;
    }
    .staffSupervise_content .userinfo .info .name i {
      font-style: normal;
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color: #70726f;
    }
    .staffSupervise_content .userinfo .info .phone {
      display: inline-block;
      /* width: 100%; */
      font-size: 0.85rem;
      //   padding: 0.15rem 0.15rem;
      width: 11rem;
      height: 1.5rem;
      background: #f6f6f6;
      text-align: center;
      border-radius: 1.125rem;
      margin-top: 0.5rem;
      color: #929299;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .staffSupervise_content .userinfo .info .phone span {
      font-size: 0.7rem;
    }
    .staffSupervise_content .userinfo .main .userBtn {
      width: 30%;
      display: flex;
      align-items: center;
      //   background: red;
    }
    .staffSupervise_content .userinfo .main .userBtn a {
      width: 3.75rem;
      height: 1.45rem;
      background: #dad9d9;
      border-radius: 0.1875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.35rem;
      font-size: 0.75rem;
      color: #b4b0b0;
    }
    .staffSupervise_content .userinfo .main .userBtn .modifys {
      background: linear-gradient(0deg, #ff3f00 39%, #ff3f00 100%);
      color: #fff;
    }
    .staffSupervise_content .tag {
      width: 100%;
      margin: 0 auto;
      font-size: 0.7rem;
      color: #929299;
      /* padding: 0.4rem 0; */
      padding-bottom: 0.47rem;
      background: #fff;
      box-sizing: border-box;
    }
    .staffSupervise_content .tag .con {
      width: 93%;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    .staffSupervise_content .tag p {
      flex-shrink: 1;
      // background: #70726f;
    }
    .staffSupervise_content .tag nav {
      flex: 1;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      /* background: skyblue; */
      margin-top: 0.5rem;
      // background: pink;
    }
    .staffSupervise_content .tag nav span {
      font-size: 0.625rem;
      padding: 0.2rem 0.7rem;
      background: #f5f5f5;
      border-radius: 1.25rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .staffSupervise_btn {
      width: 100%;
      height: 4.5rem;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      color: #000;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #f7f7f7;
    }
    .staffSupervise_btn .pic {
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.4rem;
    }
    .staffSupervise_btn .pic img {
      width: 100%;
    }
  }
}
.VipcardSettingMain .left ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
// 右边
.VipcardSettingMain .right {
  width: 40%;
  height: 50rem;
  background: #fff;
  position: relative;
}
.RechargeableCardSetting_main {
  height: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 5rem;
}
.RechargeableCardSetting_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
}
.RechargeableCardSetting_content .Left {
  width: 62%;
  /* background: skyblue; */
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .Left .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.55rem;
  display: inline-block;
  flex-shrink: 0;
}
.RechargeableCardSetting_content .Left .icon img {
  width: 100%;
}
.RechargeableCardSetting_content .Left .info {
  flex: 1;
  /* background: orchid; */
}
.RechargeableCardSetting_content .Left .info .Notice {
  width: 10rem;
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.95rem;
}
.RechargeableCardSetting_content .Left .info .Money {
  width: 10rem;
  margin-top: 0.3rem;
  font-size: 0.75rem;
  color: #ff878b;
  /* margin-top: 0.18rem; */
  /* background: orange; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RechargeableCardSetting_content .btnRight {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.RechargeableCardSetting_content .btnRight div {
  width: 4rem;
  height: 1.65rem;
  border: 1px solid #d8d8dc;
  font-size: 0.85rem;
  margin-left: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
}
.AddRechargeableBtn {
  width: 100%;
  height: 4.5rem;
  border-top: 0.1875rem solid #f4f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #fff;
  margin-top: 4rem;
}
.AddRechargeableBtn .icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.55rem;
}
.AddRechargeableBtn img {
  width: 100%;
}
.mask {
  width: 19.125rem;
  height: 15.75rem;
  background: #f2f2f2;
  border-radius: 1.25rem;
  .top {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1rem;
    background: #fff;
    letter-spacing: 0.03rem;
    margin-bottom: 0.7rem;
    i {
      position: absolute;
      right: 1rem;
      font-size: 1.3rem;
      color: #999999;
    }
  }
  .content {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f7f7f7;
    p {
      width: 88%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 0.9rem;
        font-weight: bold;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      input {
        flex: 1;
        margin-left: 1rem;
        height: 2.5rem;
        border: none;
        font-size: 0.9rem;
        text-align: left;
      }
    }
  }
  a {
    width: 80%;
    margin: 0 auto;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fa4347;
    font-size: 0.95rem;
    color: #fff;
    border-radius: 1.25rem;
    margin-top: 2.2rem;
    letter-spacing: 0.2rem;
  }
}
</style>